export var LoanStatus;
(function (LoanStatus) {
    LoanStatus[LoanStatus["FUNDING"] = 0] = "FUNDING";
    LoanStatus[LoanStatus["MIN_FUNDED"] = 1] = "MIN_FUNDED";
    LoanStatus[LoanStatus["MAX_FUNDED"] = 2] = "MAX_FUNDED";
    LoanStatus[LoanStatus["ACTIVE"] = 3] = "ACTIVE";
    LoanStatus[LoanStatus["WITHDRAWN"] = 4] = "WITHDRAWN";
    LoanStatus[LoanStatus["REPAID"] = 5] = "REPAID";
    LoanStatus[LoanStatus["LIQUIDATED"] = 6] = "LIQUIDATED";
    LoanStatus[LoanStatus["CANCELLED"] = 7] = "CANCELLED";
    LoanStatus[LoanStatus["DEFAULTED"] = 8] = "DEFAULTED";
    LoanStatus[LoanStatus["AUCTION"] = 9] = "AUCTION";
})(LoanStatus || (LoanStatus = {}));
export var Originator;
(function (Originator) {
    Originator[Originator["ALL"] = -1] = "ALL";
    Originator[Originator["BLEND"] = 0] = "BLEND";
    Originator[Originator["NFTFI"] = 1] = "NFTFI";
    Originator[Originator["X2Y2"] = 2] = "X2Y2";
    Originator[Originator["ARCADE"] = 3] = "ARCADE";
    Originator[Originator["BENDDAO"] = 4] = "BENDDAO";
    Originator[Originator["NAU"] = 5] = "NAU";
})(Originator || (Originator = {}));
export var OfferType;
(function (OfferType) {
    OfferType[OfferType["COLLECTION"] = 0] = "COLLECTION";
    OfferType[OfferType["INDIVIDUAL"] = 1] = "INDIVIDUAL";
})(OfferType || (OfferType = {}));
/**
 * SnowGensis
 */
