var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from "axios";
import axiosRetry from "axios-retry";
import { setupCache, buildWebStorage } from "axios-cache-interceptor";
import { SERVER_URL } from "./env";
import { Originator } from "@analytics/common/types";
import { COINGECKO_SIMPLE_PRICE_URI } from "./constants";
const _axios = setupCache(Axios, {
    ttl: 1000 * 60,
    storage: buildWebStorage(localStorage, "axios-cache:"),
});
axiosRetry(_axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
export const axios = _axios;
export const isValInOriginator = (val) => Object.values(Originator).some((num) => num === val);
export const getLoanOffers = (nftAddress, originator, tokenId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    let loanOfferURL = `${SERVER_URL}/loan-offers/${nftAddress}`;
    if (isValInOriginator(originator)) {
        if (originator !== Originator.ALL) {
            loanOfferURL = `${loanOfferURL}/${originator}`;
        }
        if (tokenId && originator !== Originator.BLEND) {
            loanOfferURL = `${loanOfferURL}/${tokenId}`;
        }
    }
    try {
        const response = yield axios.get(loanOfferURL, {
            id: loanOfferURL,
        });
        console.log("✅ getLoanOffers (success)", (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.length);
        return response.data;
    }
    catch (error) {
        console.error("❌ getLoanOffers (FAIL)", error);
        return [];
    }
});
/**
 * Get liens from db
 * @param nftAddress NFT Address
 * @returns
 */
export const getLiens = (nftAddress, originator, tokenId) => __awaiter(void 0, void 0, void 0, function* () {
    let lienURL = `${SERVER_URL}/liens/${nftAddress}`;
    if (isValInOriginator(originator)) {
        if (originator !== Originator.ALL) {
            lienURL = `${lienURL}/${originator}`;
        }
    }
    if (tokenId)
        lienURL = `${lienURL}/${tokenId}`;
    try {
        const response = yield axios.get(lienURL, {
            id: lienURL,
        });
        return response.data;
    }
    catch (error) {
        console.error("❌ getLiens (FAIL)", error);
        return [];
    }
});
/**
 * Get liens from db
 * @param nftAddress NFT Address
 * @returns
 */
export const getLenderHistory = (lenderAddress) => __awaiter(void 0, void 0, void 0, function* () {
    let lienURL = `${SERVER_URL}/liens/lender/${lenderAddress}`;
    try {
        const response = yield axios.get(lienURL, { id: lienURL });
        return response.data;
    }
    catch (error) {
        console.error("❌ getLenderHistory (FAIL)", error);
        return [];
    }
});
/**
 * Get list of tokenIds from db for a particular NFT collection
 * @param nftAddress NFT Address
 * @returns
 */
export const getTokenIdsForTokenOffer = (nftAddress) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const tokenIdURL = `${SERVER_URL}/loan-offers/tokenIds/${nftAddress}`;
        const resposne = yield axios.get(tokenIdURL);
        console.log("✅ getTokenIdsForTokenOffer (success)");
        return resposne.data;
    }
    catch (error) {
        console.error("❌ getTokenIdsForTokenOffer (FAIL)", error);
        return [];
    }
});
/**
 * Get list of tokenIds from db for a particular NFT collection
 * @param nftAddress NFT Address
 * @returns
 */
export const getTokenIdsForLiens = (nftAddress) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const tokenIdURL = `${SERVER_URL}/liens/tokenIds/${nftAddress}`;
        const resposne = yield axios.get(tokenIdURL);
        console.log("✅ getTokenIdsForLiens (success)");
        return resposne.data;
    }
    catch (error) {
        console.error("❌ getTokenIdsForLiens (FAIL)", error);
        return [];
    }
});
export const getCoingeckoPrice = (base, quote) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const baseId = base.toLowerCase();
        const quoteId = quote.toLowerCase();
        const url = new URL(COINGECKO_SIMPLE_PRICE_URI);
        url.searchParams.set("ids", baseId);
        url.searchParams.set("vs_currencies", quoteId);
        const response = yield axios.get(url.toString(), {
            id: url.toString(),
        });
        const result = yield response.data;
        console.log("✅ getCoingeckoPrice (success)");
        return result;
    }
    catch (error) {
        console.error("❌ getCoingeckoPrice (FAIL)", error);
        return undefined;
    }
});
export const getGasPrice = () => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const gasPriceURL = `${SERVER_URL}/util/gas-price`;
    const response = yield axios.get(gasPriceURL);
    return (_b = response.data) === null || _b === void 0 ? void 0 : _b.result;
});
/**
 *
 *
 *
 *
 *  NFT Data
 *
 *
 *
 *
 */
export const getNFT = (nftAddress, tokenId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let nftAsset = `${SERVER_URL}/nft/asset/${nftAddress}`;
        if (tokenId)
            nftAsset = `${nftAsset}/${tokenId}`;
        const response = yield axios.get(nftAsset);
        console.log("✅ getNFT (success)");
        return response.data;
    }
    catch (error) {
        console.error("❌ getNFT (FAIL)", error);
        return {};
    }
});
/**
 *
 *
 *
 *
 *  STATS
 *
 *
 *
 *
 */
export const getStats = (nftAddress, tokenId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let nftAsset = `${SERVER_URL}/stats/${nftAddress}`;
        if (tokenId)
            nftAsset = `${nftAsset}/${tokenId}`;
        const resposne = yield axios.get(nftAsset);
        console.log("✅ getStats (success)");
        return resposne.data;
    }
    catch (error) {
        console.error("❌ getStats (FAIL)", error);
        return {};
    }
});
/**
 * Get all stats for every NFT
 * @returns
 */
export const getAllStats = () => __awaiter(void 0, void 0, void 0, function* () { return yield getStats("all"); });
export const getHistorialStats = (slug) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let url = `${SERVER_URL}/stats/historical/${slug}`;
        const resposne = yield axios.get(url);
        console.log("✅ getHistorialStats (success)");
        return resposne.data;
    }
    catch (error) {
        console.error("❌ getHistorialStats (FAIL)", error);
        return undefined;
    }
});
