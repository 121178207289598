var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from "react";
import { CURRENCIES } from "utils/constants";
import { getCoingeckoPrice, getGasPrice } from "utils/queries";
const getConversionRates = () => __awaiter(void 0, void 0, void 0, function* () {
    const listOfTokens = CURRENCIES.map((currency) => currency.symbol).join(",");
    const rates = yield getCoingeckoPrice(listOfTokens, "eth,usd");
    // Add rates to CURRENCIES data
    const newCurrencyData = CURRENCIES.map((currency) => {
        const rate = rates[currency.symbol];
        return Object.assign(Object.assign({}, currency), { rate });
    });
    return newCurrencyData;
});
const emptyData = {
    currencyData: [],
    gasData: {
        LastBlock: 0,
        SafeGasPrice: 0,
        ProposeGasPrice: 0,
        FastGasPrice: 0,
        suggestBaseFee: 0,
        gasUsedRatio: [],
    },
};
export const CurrencyContext = createContext(emptyData);
const CurrencyProvider = ({ children }) => {
    const [currencyData, setCurrencyData] = useState(emptyData);
    useEffect(() => {
        const fetchCurrencyData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // Fetch the latest currency data from an API
                const currencyData = yield getConversionRates();
                const gasData = yield getGasPrice();
                setCurrencyData({ currencyData, gasData });
            }
            catch (error) {
                console.error("Error fetching currency data:", error);
            }
        });
        fetchCurrencyData();
    }, []);
    return (_jsx(CurrencyContext.Provider, Object.assign({ value: currencyData }, { children: children })));
};
export default CurrencyProvider;
