import _dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import _ from "lodash";
import _duration from "dayjs/plugin/duration";
_dayjs.extend(relativeTime);
_dayjs.extend(updateLocale);
_dayjs.extend(_duration);
_dayjs.updateLocale("en", {
    relativeTime: {
        future: "%s",
        past: "%s",
        s: "a few seconds",
        m: "1 minute",
        mm: "%d minutes",
        h: "1 hour",
        hh: "%d hours",
        d: "1 day",
        dd: "%d days",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years",
    },
});
export function secondsToDhms(seconds, options) {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    if (options === null || options === void 0 ? void 0 : options.compact) {
        d = _.round(seconds / (3600 * 24), 2);
    }
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    if (options === null || options === void 0 ? void 0 : options.onlyLargest) {
        if (options.compact) {
            if (d > 0)
                return d + " d";
            else if (m > 0)
                return m + " m";
            else if (h > 0)
                return h + " h";
            else if (s > 0)
                return s + " s";
        }
        if (d > 0) {
            return d + (d === 1 ? " day" : " days");
        }
        else if (h > 0) {
            return h + (h === 1 ? " hour" : " hours");
        }
        else if (m > 0) {
            return m + (m === 1 ? " minute" : " minutes");
        }
        else if (s > 0) {
            return s + (s === 1 ? " second" : " seconds");
        }
    }
    let dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
    let hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
    let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    if (options === null || options === void 0 ? void 0 : options.compact) {
        hDisplay = "";
        mDisplay = "";
        sDisplay = "";
    }
    return dDisplay + hDisplay + mDisplay + sDisplay;
}
export const dayjs = _dayjs;
/**
 * newDayJS creates a dayjs object from a unix timestamp denominated in seconds
 * @param timeStamp in seconds
 * @returns a dayjs object
 */
export const newDayJS = (timeStamp) => {
    return dayjs(timeStamp * 1000);
};
