/**
 * React Hook to find currency by address using context
 */
import { useContext } from "react";
import { CurrencyContext } from "context/CurrencyContext";
export const findCurrency = (CURRENCIES, from, to) => {
    const token = CURRENCIES.find((currency) => { var _a; return ((_a = currency.address) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === from.toLowerCase(); });
    return (token === null || token === void 0 ? void 0 : token.rate[to]) || 1;
};
export const convertLoanOfferCurrency = (loanOffers, convertTo = "eth", CURRENCIES) => {
    if (!loanOffers || loanOffers.length < 1) {
        console.log("⚠️ convertLoanOfferCurrency (EMPTY)", loanOffers);
        return loanOffers;
    }
    try {
        loanOffers = loanOffers.map((loanOffer) => {
            return Object.assign(Object.assign({}, loanOffer), { terms: Object.assign(Object.assign({}, loanOffer.terms), { principal: loanOffer.terms.principal *
                        findCurrency(CURRENCIES, loanOffer.terms.currency, convertTo) }) });
        });
        console.log("✅ convertLoanOfferCurrency");
        return loanOffers;
    }
    catch (e) {
        console.error("❌ convertLoanOfferCurrency (FAIL)", loanOffers);
        return loanOffers;
    }
};
export const useConvertLoanOfferCurrency = (loanOffers, convertTo = "eth") => {
    const { currencyData: CURRENCIES } = useContext(CurrencyContext);
    return convertLoanOfferCurrency(loanOffers, convertTo, CURRENCIES);
};
/**
 * Convert Lien Currency
 */
export const convertAggregateLienCurrency = (al, convertTo = "eth", CURRENCIES) => {
    if (!al || al.length < 1) {
        console.log("⚠️ useConvertAggregateLienCurrency (EMPTY)", al);
        return al;
    }
    try {
        al = al.map(({ lien, nft, batch }) => {
            return {
                nft,
                batch,
                lien: Object.assign(Object.assign({}, lien), { terms: Object.assign(Object.assign({}, lien.terms), { principal: lien.terms.principal *
                            findCurrency(CURRENCIES, lien.terms.currency, convertTo) }) }),
            };
        });
        console.log("✅ convertAggregateLienCurrency");
    }
    catch (e) {
        console.error("❌ convertAggregateLienCurrency (FAIL)", al);
    }
    return al;
};
export const useConvertAggregateLienCurrency = (aggregateLiens, convertTo = "eth") => {
    const { currencyData: CURRENCIES } = useContext(CurrencyContext);
    convertAggregateLienCurrency(aggregateLiens, convertTo, CURRENCIES);
    return aggregateLiens;
};
