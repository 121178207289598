import _ from "lodash";
import { secondsToDhms } from "./time";
import { DAY_SECS, WEI, YEAR_SECS } from "../constants";
import { Originator } from "../types";
export const calcLendingReturn = (principal, apy, durationInSecs, gasFeeEth) => {
    const _lendingReturn = principal * (apy / 100) * (durationInSecs / (365 * DAY_SECS));
    if (!gasFeeEth)
        return _lendingReturn;
    let totalGasCost = gasFeeEth * 2;
    return _lendingReturn - totalGasCost;
};
export const calcNetAPY = (principal, returnAmount, durationInSecs) => {
    return (((returnAmount * ((365 * DAY_SECS) / durationInSecs)) / principal) * 100);
};
export const getMedian = (values) => {
    const sortedValues = _.sortBy(values);
    const middleIndex = Math.floor(sortedValues.length / 2);
    if (sortedValues.length % 2 === 0) {
        const lowerValue = sortedValues[middleIndex - 1];
        const upperValue = sortedValues[middleIndex];
        return _.mean([lowerValue, upperValue]);
    }
    else {
        return sortedValues[middleIndex];
    }
};
export const getStats = (data) => {
    if (data.length === 0)
        return { min: 0, max: 0, median: 0, avg: 0 };
    const minMaxSum = _.over([_.min, _.max, _.sum]);
    const [min, max, sum] = minMaxSum(data);
    const median = getMedian(data);
    return { min, max, median, avg: Number(sum) / data.length };
};
export const calcStats = (data, currency, gasFeeEth) => {
    // Extract the required properties from loanOffers
    const apys = data.map((item) => item.apy);
    const principals = data.map((item) => item.principal);
    const durations = data.map((item) => item.duration);
    // Calculate statistical data for apy
    // console.log("HERE", apys);
    const { avg: avgApy } = getStats(apys) || { avg: -1 };
    const { avg: avgPrincipal } = getStats(principals) || { avg: -1 };
    // Calculate statistical data for duration
    const filteredDurations = durations.filter((duration) => duration !== null);
    const { avg: avgDuration } = getStats(filteredDurations) || { avg: -1 };
    // Return calculation
    const avgLendingReturn = calcLendingReturn(avgPrincipal, avgApy * 100, avgDuration, Number(gasFeeEth));
    const avgNetAPY = calcNetAPY(avgPrincipal, avgLendingReturn, avgDuration);
    const gasCostPercent = ((Number(gasFeeEth) * 2) / avgLendingReturn) * 100;
    return [
        {
            val: avgNetAPY.toFixed(2),
            name: "Avg Net APY",
            unit: "%",
        },
        {
            val: avgLendingReturn.toFixed(3),
            name: "Avg Net Return",
            unit: currency,
        },
        {
            val: gasCostPercent.toFixed(2),
            name: "Avg Gas Cost",
            unit: "%",
        },
        { val: avgApy * 100, name: "Avg APY", unit: "%" },
        { val: avgPrincipal, name: "Avg Principal", unit: currency },
        {
            val: secondsToDhms(avgDuration, { onlyLargest: true }),
            name: "Avg Duration",
            unit: null,
        },
        { val: data[0], name: "Most recent", unit: "ago" },
        { val: data.length, name: "Total Count", unit: null },
        {
            val: _.sum(principals).toFixed(2),
            name: "Total Amount",
            unit: currency,
        },
    ];
};
const calculateMedianAbsoluteDeviation = (values, median) => {
    const deviations = values.map((value) => Math.abs(value - median));
    const mad = getMedian(deviations);
    return mad;
};
export const removeMADOutlier = (stats, parameters) => {
    const { principalThreshold, apyThreshold, durationThreshold } = parameters;
    // Calculate the median and median absolute deviation (MAD) for principal
    const principalValues = [];
    const apyValues = [];
    const durationsValues = [];
    stats.forEach((stat) => {
        principalValues.push(stat.principal);
        apyValues.push(stat.apy);
        if (stat.duration)
            durationsValues.push(stat.duration);
    });
    const principalMedian = getMedian(principalValues);
    const principalMad = calculateMedianAbsoluteDeviation(principalValues, principalMedian);
    const apyMedian = getMedian(apyValues);
    const apyMad = calculateMedianAbsoluteDeviation(apyValues, apyMedian);
    const filteredDurations = durationsValues.filter((duration) => !isNaN(duration));
    const medianDuration = getMedian(filteredDurations);
    const durationMad = calculateMedianAbsoluteDeviation(filteredDurations, medianDuration);
    // Filter outliers based on principal MAD, apy MAD, and duration MAD
    const principalThresholdValue = principalMedian + principalThreshold * principalMad;
    const apyThresholdValue = apyMedian + apyThreshold * apyMad;
    const durationThresholdValue = medianDuration + durationThreshold * durationMad;
    const filteredStats = stats.filter((stat) => {
        const isPrincipalOutlier = Math.abs(stat.principal - principalMedian) > principalThresholdValue;
        const isApyOutlier = apyThresholdValue > 0 &&
            Math.abs(stat.apy - apyMedian) > apyThresholdValue;
        const isDurationOutlier = stat.duration &&
            Math.abs(stat.duration - medianDuration) > durationThresholdValue;
        return !isPrincipalOutlier && !isApyOutlier && !isDurationOutlier;
    });
    return filteredStats;
};
export const isValInOriginator = (val) => Object.values(Originator).some((num) => num === val);
export const calcAPYFromTerms = (repayment, principal, duration) => {
    const durationRate = (repayment / WEI - principal / WEI) / (principal / WEI);
    const durationMultiple = YEAR_SECS / duration;
    // decimal points e.g 0.2 = 20%
    return durationRate * durationMultiple;
};
