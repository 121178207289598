import _ from "lodash";
import { secondsToDhms, dayjs } from "utils/time";
import { getMedian } from "utils/stats";
import { calcLendingReturn, calcNetAPY, makeDuration, } from "@analytics/common/utils";
export const createStats = (data) => {
    if (data.length === 0)
        return { min: -1, max: -1, median: -1, avg: -1 };
    const _min = _.min(data) || -1;
    const _max = _.max(data) || -1;
    const med = getMedian(data);
    const min = Number(_min.toFixed(2));
    const max = Number(_max.toFixed(2));
    const median = med ? Number(getMedian(data).toFixed(4)) : med;
    const avg = Number((_.sum(data) / data.length).toFixed(4));
    return { min, max, median, avg };
};
export const calcReturnMinusGas = (principal, apy, duration, gas, currency) => {
    // Return calculation
    const lendingReturn = calcLendingReturn(principal, apy * 100, duration, gas);
    const avgNetAPY = calcNetAPY(principal, lendingReturn, duration);
    const gasCostPercent = ((gas * 2) / lendingReturn) * 100;
    return {
        raw: { lendingReturn, avgNetAPY, gasCostPercent },
        formatted: [
            {
                val: avgNetAPY,
                name: "Avg net apy (- gas)",
                unit: "%",
            },
            {
                val: lendingReturn,
                name: "Avg net return (- gas)",
                unit: currency,
            },
            {
                val: gasCostPercent,
                name: "Avg gas cost as %",
                unit: "%",
            },
        ],
    };
};
export const calcStats = (data, currency) => {
    if (data.length === 0)
        return { raw: undefined, formatted: [] };
    // Extract the required properties from loanOffers
    const apys = data.map((item) => item.apy);
    const principals = data.map((item) => item.principal);
    const durations = data.map((item) => item.duration);
    // Calculate statistical data for apy
    const { max: maxApy, median: medianApy } = createStats(apys);
    const { max: maxPrincipal, median: medianPrincipal } = createStats(principals);
    // Calculate statistical data for duration
    const filteredDurations = durations.filter((duration) => duration !== null);
    const { avg: avgDuration, median: medianDuration } = createStats(filteredDurations);
    let fromNow = "ERR";
    try {
        if (data[0].createdAt) {
            fromNow = dayjs.unix(data[0].createdAt).fromNow();
        }
    }
    catch (error) {
        console.error(error);
    }
    return {
        raw: {
            maxPrincipal,
            maxApy,
            medianApy,
            medianPrincipal,
            avgDuration,
            medianDuration,
        },
        formatted: [
            { val: maxPrincipal, name: "Max amount", unit: currency },
            { val: maxApy * 100, name: "Max apy", unit: "%" },
            {
                val: secondsToDhms(avgDuration, { onlyLargest: true }),
                name: "Avg length",
                unit: "",
            },
            { val: fromNow, name: "Last", unit: "ago" },
            { val: data.length, name: "Total count", unit: "" },
            {
                val: _.sum(principals),
                name: "Total amount",
                unit: currency,
            },
            {
                val: medianApy * 100,
                name: "Median apy",
                unit: "%",
            },
            {
                val: medianPrincipal,
                name: "Median amount",
                unit: currency,
            },
        ],
    };
};
export const getCombinedStats = (data, currency, gasFeeEth) => {
    var _a;
    const loanOfferStats = calcStats(data, currency);
    if (!loanOfferStats ||
        !loanOfferStats.raw ||
        ((_a = loanOfferStats === null || loanOfferStats === void 0 ? void 0 : loanOfferStats.formatted) === null || _a === void 0 ? void 0 : _a.length) < 1) {
        return [];
    }
    const { maxApy, maxPrincipal, avgDuration } = loanOfferStats.raw;
    const loanOfferNetReturnStats = calcReturnMinusGas(maxPrincipal, maxApy, avgDuration, gasFeeEth, currency);
    return [...loanOfferNetReturnStats.formatted, ...loanOfferStats.formatted];
};
export const getCombinedStatsBlend = (data, currency, gasFeeEth) => {
    var _a;
    const loanOfferStats = calcStats(data, currency);
    if (!loanOfferStats ||
        !loanOfferStats.raw ||
        ((_a = loanOfferStats === null || loanOfferStats === void 0 ? void 0 : loanOfferStats.formatted) === null || _a === void 0 ? void 0 : _a.length) < 1) {
        return [];
    }
    const EST_DURATION = makeDuration(5, "days");
    const { maxApy, maxPrincipal } = loanOfferStats.raw;
    const loanOfferNetReturnStats = calcReturnMinusGas(maxPrincipal, maxApy, EST_DURATION, gasFeeEth, currency);
    loanOfferStats.formatted[2] = {
        val: secondsToDhms(EST_DURATION),
        name: "Avg Length",
        unit: "",
    };
    return [...loanOfferNetReturnStats.formatted, ...loanOfferStats.formatted];
};
