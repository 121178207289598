import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { secondsToDhms } from "utils/time";
import { LoanStatus, } from "@analytics/common/types";
import { getLienStatusString, getOriginatorString, now, shortenIfAddress, } from "@analytics/common/utils";
import { NFTStatsContext } from "context/NFTStats";
import TableSortable, { formatPercentage, formatDateTime, } from "components/TableSortable";
import { formatNumber } from "utils/numbers";
import { ALL_NFTS, ETHERSCAN_URI } from "@analytics/common/constants";
import { useFavorites } from "hooks/useFavorites";
const getEtherscanLink = (address) => (_jsx("a", Object.assign({ href: `${ETHERSCAN_URI}/address/${address}`, target: "_blank", rel: "noreferrer" }, { children: shortenIfAddress(address) })));
const createData = (nftStats) => (al) => {
    var _a, _b, _c, _d;
    const { terms } = al.lien;
    const { borrower, tokenId, startedOn, repaidAt } = al.lien;
    const data = nftStats.find((el) => el.nftAddress === al.lien.contractAddress.toLowerCase());
    const ltv = ((_b = (_a = data === null || data === void 0 ? void 0 : data.stats) === null || _a === void 0 ? void 0 : _a.nft) === null || _b === void 0 ? void 0 : _b.floor_price)
        ? terms.principal / ((_d = (_c = data === null || data === void 0 ? void 0 : data.stats) === null || _c === void 0 ? void 0 : _c.nft) === null || _d === void 0 ? void 0 : _d.floor_price)
        : -1;
    const isActive = terms.status === LoanStatus.ACTIVE ||
        terms.status === LoanStatus.MAX_FUNDED ||
        terms.status === LoanStatus.MIN_FUNDED;
    return {
        icon: al.nft.imageUrl ? `${al.nft.imageUrl}?w=64&h=64?w=64&h=64` : "",
        ltv,
        status: terms.status,
        repayOn: terms.duration ? terms.duration + al.lien.startedOn : -1,
        apy: terms.apy,
        principal: terms.principal,
        daysLoaned: isActive
            ? now - startedOn
            : repaidAt
                ? repaidAt - startedOn
                : -1,
        duration: terms.duration
            ? terms.duration
            : repaidAt
                ? repaidAt - startedOn
                : -1,
        borrower: (borrower === null || borrower === void 0 ? void 0 : borrower.address) || "n/a",
        tokenId: (tokenId === null || tokenId === void 0 ? void 0 : tokenId.toString()) || "n/a",
        amountRepaid: terms.amountRepaid ? terms.amountRepaid : -1,
        createdOn: startedOn,
        pnl: terms.status === LoanStatus.REPAID && terms.amountOwed
            ? terms.amountOwed - terms.principal
            : -1,
        repaidOn: repaidAt || -1,
        originator: al.lien.originator,
    };
};
const headCells = [
    {
        id: "icon",
        numeric: false,
        disablePadding: true,
        label: "Icon",
        width: "55px",
    },
    {
        id: "ltv",
        numeric: false,
        disablePadding: true,
        label: "LTV",
        width: "75px",
    },
    {
        id: "principal",
        numeric: true,
        disablePadding: false,
        label: "Principal",
        width: "65px",
    },
    {
        id: "status",
        numeric: true,
        disablePadding: true,
        label: "Status",
        width: "60px",
    },
    {
        id: "apy",
        numeric: true,
        disablePadding: false,
        label: "APY",
        width: "65px",
    },
    {
        id: "daysLoaned",
        numeric: true,
        disablePadding: false,
        label: "Days Loaned",
        width: "150px",
    },
    {
        id: "duration",
        numeric: true,
        disablePadding: false,
        label: "Duration",
    },
    {
        id: "originator",
        numeric: true,
        disablePadding: false,
        label: "Originator",
    },
    {
        id: "amountRepaid",
        numeric: true,
        disablePadding: true,
        label: "Repaid Amount",
    },
    {
        id: "pnl",
        numeric: true,
        disablePadding: true,
        label: "PnL",
        width: "60px",
    },
    {
        id: "borrower",
        numeric: false,
        disablePadding: true,
        label: "Borrower",
        width: "100px",
    },
    {
        id: "tokenId",
        numeric: false,
        disablePadding: true,
        label: "ID",
        width: "80px",
    },
    {
        id: "createdOn",
        numeric: true,
        disablePadding: false,
        label: "Created On",
    },
    {
        id: "repayOn",
        numeric: true,
        disablePadding: false,
        label: "Repay On",
    },
    {
        id: "repaidOn",
        numeric: true,
        disablePadding: false,
        label: "Repaid On",
    },
];
export const getLTVClass = (value) => {
    if (value > 0.9)
        return "stats-negative ";
    else if (value > 0.7)
        return "stats-warning ";
    else
        return "stats-positive ";
};
const PortfolioTable = React.memo(({ liens }) => {
    const nftStats = React.useContext(NFTStatsContext);
    const { sortedNFTs } = useFavorites({ allNfts: ALL_NFTS });
    const findNFTByAddress = (address) => sortedNFTs.find((nft) => nft.nftAddress === address);
    const formatCell = (key, value) => {
        var _a;
        switch (key) {
            case "icon":
                return (_jsx("img", { className: "icon", alt: "nft-icon", src: value, width: 50, height: 50 }));
            case "ltv":
                return (_jsx("span", Object.assign({ className: getLTVClass(value) }, { children: formatPercentage(value) })));
            case "status":
                const statusString = getLienStatusString(value);
                let fontWeight = 300;
                let color = "black";
                switch (value) {
                    case LoanStatus.REPAID:
                        color = "blue";
                        break;
                    case LoanStatus.MAX_FUNDED:
                    case LoanStatus.ACTIVE:
                        color = "green";
                        fontWeight = 800;
                        break;
                    case LoanStatus.LIQUIDATED:
                        fontWeight = 800;
                        break;
                }
                return _jsx("span", Object.assign({ style: { color, fontWeight } }, { children: statusString }));
            case "apy":
                return formatNumber(value);
            case "amountRepaid":
                return value > -1 ? formatNumber(value, 4) : "-";
            case "principal":
                return formatNumber(value);
            case "daysLoaned":
                return secondsToDhms(value, { compact: true }) || "-";
            case "duration":
                return secondsToDhms(value, { onlyLargest: true }) || "-";
            case "originator":
                return getOriginatorString(value);
            case "nftAddress":
                return ((_a = findNFTByAddress(value)) === null || _a === void 0 ? void 0 : _a.name) || shortenIfAddress(value);
            case "borrower":
                return getEtherscanLink(value);
            case "tokenId":
                return value;
            case "pnl":
                return value > -1 ? formatNumber(value) : "-";
            case "createdOn":
                return value > -1 ? formatDateTime(value, { compact: true }) : "-";
            case "repaidOn":
                return value > -1 ? formatDateTime(value, { compact: true }) : "-";
            case "repayOn":
                return value > -1 ? formatDateTime(value, { compact: true }) : "-";
            default:
                return value;
        }
    };
    return (_jsx("div", Object.assign({ style: { height: 600 }, className: "loan-lien-box" }, { children: _jsx(TableSortable, { data: liens, headCells: headCells, createRowData: createData(nftStats), formatCell: formatCell }) })));
});
export default PortfolioTable;
