import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from "@mui/material";
import "./LenderFooter.sass";
import { Originator } from "@analytics/common/types";
const LenderFooter = ({ nft, originator, tokenId }) => {
    let slug = nft === null || nft === void 0 ? void 0 : nft.slug;
    // Slugs for Blend are different than the ones for FloorPrice stats
    if (!slug)
        return _jsx(_Fragment, {});
    if (slug === "bored-ape-yacht-club" || slug === "pudgy-penguins") {
        slug = slug.replaceAll("-", "");
    }
    let blendURL = `https://blur.io/collection/${slug}/loans`;
    let nftFiURL = `https://app.nftfi.com/collection/${nft.nftAddress}`;
    const BlendButton = (_jsx(Button, Object.assign({ className: "action-button", variant: "contained", target: "_blank", fullWidth: true, href: blendURL }, { children: "Blend" }), 1));
    const NftFiButton = (url, text) => (_jsx(Button, Object.assign({ className: "action-button", variant: "contained", fullWidth: true, target: "_blank", href: url }, { children: text ? text : "NFTFi" }), 2));
    let buttons = [];
    switch (originator) {
        case Originator.BLEND:
            buttons = [BlendButton];
            break;
        case Originator.NFTFI:
            let text = undefined;
            if (tokenId) {
                nftFiURL = `https://app.nftfi.com/assets/${nft.nftAddress}/${tokenId}`;
                text = `NftFi - ${tokenId}`;
            }
            buttons = [NftFiButton(nftFiURL, text)];
            break;
        case Originator.ALL:
            buttons = [BlendButton, NftFiButton(nftFiURL)];
            break;
        default:
            break;
    }
    return (_jsxs("footer", Object.assign({ id: "lender-footer-nav" }, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: "Lend on: " })), _jsx("div", Object.assign({ className: "actions" }, { children: buttons }))] })));
};
export default LenderFooter;
