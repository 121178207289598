var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext, useEffect, useState } from "react";
import { getLenderHistory, getLiens } from "utils/queries";
import { removeOutliersIQRLien } from "utils/stats";
import { convertAggregateLienCurrency } from "./useConvertCurrency";
import { CurrencyContext } from "context/CurrencyContext";
// Remove loanOffers where loanOffer.offeredOn is undefined or null
const removeEmptyVals = (al) => al.filter(({ lien }) => lien.terms.apy > 0 && lien.terms.principal > 0);
const filterAndConvertLiens = (al, currencyData, opts) => {
    if (opts) {
        if (opts.filter) {
            if (opts.filter.empty)
                al = removeEmptyVals(al);
            if (opts.filter.outliers)
                al = removeOutliersIQRLien(al);
        }
        if (opts.convertCurrencyTo && currencyData) {
            al = convertAggregateLienCurrency(al, opts === null || opts === void 0 ? void 0 : opts.convertCurrencyTo, currencyData);
        }
    }
    return al;
};
export const useLiens = (nft, originator, opts) => {
    const { currencyData } = useContext(CurrencyContext);
    const [loading, setLoading] = useState(true);
    const [aggregateLiens, setAggregateLiens] = useState([]);
    // Fetch data
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            try {
                let data = yield getLiens(nft.nftAddress, originator);
                setAggregateLiens(data);
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
            }
        });
        fetchData();
    }, [nft, originator]);
    return [loading, filterAndConvertLiens(aggregateLiens, currencyData, opts)];
};
export const useLenderHistory = (lenderAddress, opts) => {
    const { currencyData } = useContext(CurrencyContext);
    const [loading, setLoading] = useState(true);
    const [aggregateLiens, setAggregateLiens] = useState([]);
    // Fetch data
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            try {
                let data = yield getLenderHistory(lenderAddress);
                setAggregateLiens(data);
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
            }
        });
        fetchData();
    }, [lenderAddress]);
    return [loading, filterAndConvertLiens(aggregateLiens, currencyData, opts)];
};
