import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChartJS } from "../Charts/Setup";
import { Scatter } from "react-chartjs-2";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, } from "@mui/material";
import { NFTStatsContext } from "context/NFTStats";
import { useContext } from "react";
// Register chartjs
ChartJS();
const durationToDays = (epochTime) => {
    const milliseconds = epochTime * 1000;
    // Calculate the number of days
    return Math.floor(milliseconds / (24 * 60 * 60 * 1000));
};
const createScatterChartData = (loanOffers, labels) => {
    const data = loanOffers.map(({ terms }) => ({
        x: terms.principal.toFixed(2),
        y: (terms.apy * 100).toFixed(2),
    }));
    const borderColor = loanOffers.map(({ terms }) => {
        const days = durationToDays(terms.duration || 0);
        const opacity = 0.6;
        if (days < 3) {
            return `rgba(10, 162, 172, ${opacity})`;
        }
        else if (days < 7) {
            return `rgba(125, 92, 92, ${opacity})`;
        }
        else if (days < 14) {
            return `rgba(125, 222, 212, ${opacity}1)`;
        }
        return `rgba(0, 0, 0, ${opacity}1)`;
    });
    return {
        labels,
        datasets: [
            {
                label: "Loan Offer: Principal VS APY",
                data: data,
                backgroundColor: borderColor,
                borderColor: "rgba(10, 162, 172, 1)",
                pointRadius: 7,
                pointHoverRadius: 7,
            },
        ],
    };
};
/**
 * Loan Offers graphed as principal and interest rate over time
 * @param param0 array of loan offers
 * @returns a scatter plot of loan offers
 */
export const OfferPrincipalOverTime = ({ loanOffers, }) => {
    if (!loanOffers || loanOffers.length < 1)
        return _jsx("div", { children: "Loading loan offers ..." });
    const chartData = createScatterChartData(loanOffers, ["principal", "apy"]);
    return (_jsx(Scatter, { height: "100", className: "scatter-chart", data: chartData, options: {
            animation: false,
            scales: {
                x: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: "Principal",
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: "APY",
                    },
                },
            },
        } }));
};
/**
 * Loan Offers graphed interest rate over time
 * @param data
 * @returns
 */
export const ApySegmentTable = ({ data, nftAddress, }) => {
    const nftStats = useContext(NFTStatsContext);
    const nftData = nftStats.find((nft) => nft.nftAddress === nftAddress);
    return (_jsxs(Paper, Object.assign({ elevation: 3, style: { padding: "16px", marginTop: "16px" } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", gutterBottom: true }, { children: "Loan Offers by APY Segment" })), _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Principal Segment" }), _jsx(TableCell, { children: "Lowest APY" }), _jsx(TableCell, { children: "Count" }), _jsx(TableCell, Object.assign({ align: "right" }, { children: "APY" }))] }) }), _jsx(TableBody, { children: data.map((row, index) => {
                            var _a, _b, _c, _d;
                            return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: row.principal.toFixed(2) }), _jsx(TableCell, Object.assign({ component: "th", scope: "row" }, { children: `${(row.apy * 100).toFixed(2)}%` })), _jsx(TableCell, Object.assign({ component: "th", scope: "row" }, { children: row.count })), _jsx(TableCell, Object.assign({ align: "right", scope: "row" }, { children: ((_b = (_a = nftData === null || nftData === void 0 ? void 0 : nftData.stats) === null || _a === void 0 ? void 0 : _a.nft) === null || _b === void 0 ? void 0 : _b.floor_price)
                                            ? `${((row.principal / ((_d = (_c = nftData === null || nftData === void 0 ? void 0 : nftData.stats) === null || _c === void 0 ? void 0 : _c.nft) === null || _d === void 0 ? void 0 : _d.floor_price)) *
                                                100).toFixed(2)}%`
                                            : -1 }))] }, index));
                        }) })] })] })));
};
