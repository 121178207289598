var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { getHistorialStats } from "utils/queries";
import { dayjs } from "utils/time";
const useNFTHistorialStats = (nft, dateRange) => {
    const [loading, setLoading] = useState(true);
    const [nftData, setNFTData] = useState();
    const [nftDataCopy, setNFTDataCopy] = useState();
    // Fetch data
    useEffect(() => {
        const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            try {
                const historialRes = yield getHistorialStats(nft.slug);
                if (historialRes) {
                    setNFTData(historialRes);
                    setNFTDataCopy(historialRes);
                }
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
            }
        });
        fetch();
    }, [nft]);
    // Filter data by timestamp
    // IMPORTANT: Do not use nftData in the dependency array
    //            because it will cause an infinite loop
    //            Update
    useEffect(() => {
        if (nftData && dateRange && dateRange[0] && dateRange[1]) {
            let timestamps = [];
            let floorEth = [];
            let floorUsd = [];
            let salesCount = [];
            let volumeEth = [];
            let volumeUsd = [];
            for (let i = 0; i < nftData.timestamps.length; i++) {
                const el = nftData === null || nftData === void 0 ? void 0 : nftData.timestamps[i];
                if (el) {
                    const time = dayjs(el);
                    if (time.isAfter(dateRange[0]) && time.isBefore(dateRange[1])) {
                        timestamps.push(el);
                        floorEth.push(nftData === null || nftData === void 0 ? void 0 : nftData.floorEth[i]);
                        floorUsd.push(nftData === null || nftData === void 0 ? void 0 : nftData.floorUsd[i]);
                        salesCount.push(nftData === null || nftData === void 0 ? void 0 : nftData.volumeEth[i]);
                        volumeEth.push(nftData === null || nftData === void 0 ? void 0 : nftData.volumeEth[i]);
                        volumeUsd.push(nftData === null || nftData === void 0 ? void 0 : nftData.volumeUsd[i]);
                    }
                }
            }
            setNFTDataCopy({
                slug: nftData.slug,
                granularity: nftData.granularity,
                timestamps,
                floorEth,
                floorUsd,
                salesCount,
                volumeEth,
                volumeUsd,
            });
        }
    }, [nftData, dateRange]);
    return [loading, nftDataCopy];
};
export default useNFTHistorialStats;
