import _ from "lodash";
// Standard Deviation
export const removeOutliers = (data, threshold = 3, axis = "y") => {
    // Calculate the mean and standard deviation of the data
    const meanY = data.reduce((sum, point) => sum + point.y, 0) / data.length;
    const meanX = data.reduce((sum, point) => sum + point.x, 0) / data.length;
    const stdDevY = Math.sqrt(data.reduce((sum, point) => sum + Math.pow((point.y - meanY), 2), 0) / data.length);
    const stdDevX = Math.sqrt(data.reduce((sum, point) => sum + Math.pow((point.x - meanX), 2), 0) / data.length);
    // Define the threshold for outliers (e.g., 3 standard deviations from the mean)
    const outlierThresholdY = threshold * stdDevY;
    const outlierThresholdX = threshold * stdDevX;
    // Filter out the outliers from the data
    const filteredData = data.filter((point) => {
        if (axis === "y")
            return Math.abs(point.y - meanY) <= outlierThresholdY;
        else
            return Math.abs(point.x - meanX) <= outlierThresholdX;
    });
    return filteredData;
};
/**
 * Calculate the Median Absolute Deviation (MAD) of a dataset
 */
export const getMedian = (values) => {
    const sortedValues = _.sortBy(values);
    const middleIndex = Math.floor(sortedValues.length / 2);
    if (sortedValues.length % 2 === 0) {
        const lowerValue = sortedValues[middleIndex - 1];
        const upperValue = sortedValues[middleIndex];
        return _.mean([lowerValue, upperValue]);
    }
    else {
        return sortedValues[middleIndex];
    }
};
export const removeMADOutlier = (stats, parameters) => {
    const { principalThreshold, apyThreshold, durationThreshold } = parameters;
    // Calculate the median and median absolute deviation (MAD) for principal
    const principalValues = stats.map((stat) => stat.principal);
    const principalMedian = getMedian(principalValues);
    const principalMad = calculateMedianAbsoluteDeviation(principalValues, principalMedian);
    // Calculate the median and median absolute deviation (MAD) for apy
    const apyValues = stats.map((stat) => stat.apy);
    const apyMedian = getMedian(apyValues);
    const apyMad = calculateMedianAbsoluteDeviation(apyValues, apyMedian);
    const durationsValues = stats.map((stat) => stat.duration);
    const filteredDurations = _.filter(durationsValues, (duration) => duration !== null);
    const medianDuration = getMedian(filteredDurations);
    const durationMad = calculateMedianAbsoluteDeviation(filteredDurations, medianDuration);
    // Filter outliers based on principal MAD
    const principalThresholdValue = principalMedian + principalThreshold * principalMad;
    let filteredStats = stats.filter((stat) => {
        return (Math.abs(stat.principal - principalMedian) <= principalThresholdValue);
    });
    // Filter outliers based on apy MAD
    let apyThresholdValue = apyMedian + apyThreshold * apyMad;
    filteredStats = filteredStats.filter((stat) => {
        // do not filter if MAD is 0
        if (apyThresholdValue <= 0)
            return true;
        return Math.abs(stat.apy - apyMedian) <= apyThresholdValue;
    });
    let durationThresholdValue = medianDuration + durationThreshold * durationMad;
    if (!isNaN(durationThresholdValue)) {
        filteredStats = filteredStats.filter(({ duration }) => duration
            ? Math.abs(duration - medianDuration) <= durationThresholdValue
            : null);
    }
    return filteredStats;
};
/**
 *
 * @param al
 * @param parameters
 * @returns
 */
export const removeMADOutlierAL = (al, parameters) => {
    // const { principalThreshold, apyThreshold, durationThreshold } = parameters;
    const { principalThreshold, apyThreshold } = parameters;
    // Calculate the median and median absolute deviation (MAD) for principal
    const principalValues = al.map(({ lien }) => Number(lien.terms.principal));
    const principalMedian = getMedian(principalValues);
    const principalMad = calculateMedianAbsoluteDeviation(principalValues, principalMedian);
    // Calculate the median and median absolute deviation (MAD) for apy
    const apyValues = al.map(({ lien }) => Number(lien.terms.apy));
    const apyMedian = getMedian(apyValues);
    const apyMad = calculateMedianAbsoluteDeviation(apyValues, apyMedian);
    // const durationsValues = al.map(({ lien }) => Number(lien.terms.duration));
    // const filteredDurations = _.filter(
    //   durationsValues,
    //   (duration) => duration !== null
    // ) as number[];
    // const medianDuration = getMedian(filteredDurations);
    // const durationMad = calculateMedianAbsoluteDeviation(
    //   filteredDurations,
    //   medianDuration
    // );
    // Filter outliers based on principal MAD
    const principalThresholdValue = principalMedian + principalThreshold * principalMad;
    // let filteredStats = loanOffers;
    let filteredStats = al.filter(({ lien }) => {
        return (Math.abs(lien.terms.principal - principalMedian) <=
            principalThresholdValue);
    });
    // Filter outliers based on apy MAD
    let apyThresholdValue = apyMedian + apyThreshold * apyMad;
    filteredStats = filteredStats.filter(({ lien }) => {
        // do not filter if MAD is 0
        if (apyThresholdValue <= 0)
            return true;
        return Math.abs(lien.terms.apy - apyMedian) <= apyThresholdValue;
    });
    // let durationThresholdValue = medianDuration + durationThreshold * durationMad;
    // if (!isNaN(durationThresholdValue)) {
    //   filteredStats = filteredStats.filter(({ lien }) =>
    //     lien.terms.duration
    //       ? Math.abs(lien.terms.duration - medianDuration) <=
    //         durationThresholdValue
    //       : null
    //   );
    // }
    return filteredStats;
};
const calculateMedianAbsoluteDeviation = (values, median) => {
    const deviations = values.map((value) => Math.abs(value - median));
    const mad = getMedian(deviations);
    return mad;
};
export const getIQRBounds = (sortedPrincipal, sortedApy, iqrMultiplier = 1.5) => {
    const q1Principal = sortedPrincipal[Math.floor(sortedPrincipal.length * 0.25)];
    const q3Principal = sortedPrincipal[Math.floor(sortedPrincipal.length * 0.75)];
    const iqrPrincipal = q3Principal - q1Principal;
    const q1Apy = sortedApy[Math.floor(sortedApy.length * 0.25)];
    const q3Apy = sortedApy[Math.floor(sortedApy.length * 0.75)];
    const iqrApy = q3Apy - q1Apy;
    return {
        principal: {
            lowerBound: q1Principal - iqrMultiplier * iqrPrincipal,
            upperBound: q3Principal + iqrMultiplier * iqrPrincipal,
        },
        apy: {
            lowerBound: q1Apy - iqrMultiplier * iqrApy,
            upperBound: q3Apy + iqrMultiplier * iqrApy,
        },
    };
};
/**
 * Remove outliers using the interquartile range (IQR) method
 * @param data LoanOffer[]
 * @param iqrMultiplier threshold
 * @returns LoanOffer[]
 */
export const removeOutliersIQRLoanOffers = (data) => {
    const validData = data;
    const sortedPrincipal = validData
        .map((item) => item.terms.principal)
        .sort((a, b) => a - b);
    const sortedApy = validData
        .map((item) => item.terms.apy)
        .sort((a, b) => a - b);
    const bounds = getIQRBounds(sortedPrincipal, sortedApy);
    return data.filter(({ terms }) => {
        const isPrincipalOutlier = terms.principal < bounds.principal.lowerBound ||
            terms.principal > bounds.principal.upperBound;
        const isApyOutlier = terms.apy < bounds.apy.lowerBound || terms.apy > bounds.apy.upperBound;
        return !(isPrincipalOutlier || isApyOutlier);
    });
};
export const removeOutliersIQRLien = (data) => {
    const validData = data;
    const sortedPrincipal = validData
        .map(({ lien }) => lien.terms.principal)
        .sort((a, b) => a - b);
    const sortedApy = validData
        .map(({ lien }) => lien.terms.apy)
        .sort((a, b) => a - b);
    const bounds = getIQRBounds(sortedPrincipal, sortedApy);
    return data.filter(({ lien }) => {
        const isPrincipalOutlier = lien.terms.principal < bounds.principal.lowerBound ||
            lien.terms.principal > bounds.principal.upperBound;
        const isApyOutlier = lien.terms.apy < bounds.apy.lowerBound ||
            lien.terms.apy > bounds.apy.upperBound;
        return !(isPrincipalOutlier || isApyOutlier);
    });
};
