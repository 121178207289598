import { useEffect, useState } from "react";
export const useFavorites = ({ allNfts }) => {
    // Load favorites from local storage and set initial state
    let initialFavorites = [];
    try {
        initialFavorites = JSON.parse(localStorage.getItem("favorites") || "[]");
    }
    catch (error) {
        console.error("useFavorites - parse JSON", error);
    }
    const [favorites, setFavorites] = useState(initialFavorites);
    const [sortedNFTs, setSortedNFTs] = useState([]);
    // Save favorites to local storage whenever the 'favorites' state changes
    useEffect(() => {
        localStorage.setItem("favorites", JSON.stringify(favorites));
    }, [favorites]);
    useEffect(() => {
        // Sort NFTs based on whether they are favorite
        const sorted = [...allNfts].sort((a, b) => {
            const aIsFavorite = favorites.includes(a.nftAddress);
            const bIsFavorite = favorites.includes(b.nftAddress);
            // Put favorites at the top of the list
            if (aIsFavorite && !bIsFavorite)
                return -1;
            if (!aIsFavorite && bIsFavorite)
                return 1;
            return 0;
        });
        setSortedNFTs(sorted);
    }, [favorites, allNfts]);
    const addFavorite = (nftAddress) => {
        setFavorites((prevFavorites) => {
            if (!prevFavorites.includes(nftAddress)) {
                return [...prevFavorites, nftAddress];
            }
            else {
                return prevFavorites;
            }
        });
    };
    const removeFavorite = (nftAddress) => {
        setFavorites((prevFavorites) => {
            if (prevFavorites.includes(nftAddress)) {
                return prevFavorites.filter((fav) => fav !== nftAddress);
            }
            else {
                return prevFavorites;
            }
        });
    };
    const isFavorite = (nftAddress) => {
        return favorites.includes(nftAddress);
    };
    return { favorites, addFavorite, removeFavorite, isFavorite, sortedNFTs };
};
