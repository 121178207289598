import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { secondsToDhms } from "utils/time";
import TableSortable, { formatPercentage, formatDateTime, } from "components/TableSortable";
import { formatNumber } from "utils/numbers";
const createData = (al) => {
    var _a, _b;
    const { terms } = al.lien;
    return {
        tokenId: ((_a = al.lien.tokenId) === null || _a === void 0 ? void 0 : _a.toString()) || "n/a",
        apy: terms.apy,
        principal: terms.principal,
        duration: terms.duration || -1,
        lender: ((_b = al.lien.lender) === null || _b === void 0 ? void 0 : _b.address) || "n/a",
        createdOn: al.lien.startedOn,
    };
};
const headCells = [
    {
        id: "tokenId",
        numeric: false,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "apy",
        numeric: true,
        disablePadding: false,
        label: "APY",
    },
    {
        id: "principal",
        numeric: true,
        disablePadding: false,
        label: "Principal",
    },
    {
        id: "duration",
        numeric: true,
        disablePadding: false,
        label: "Duration",
    },
    {
        id: "createdOn",
        numeric: true,
        disablePadding: false,
        label: "Created On",
    },
];
const LienTable = React.memo(({ liens }) => {
    const formatCell = (key, value) => {
        switch (key) {
            case "apy":
                return formatPercentage(value);
            case "principal":
                return formatNumber(value);
            case "duration":
                return secondsToDhms(value, { onlyLargest: true });
            case "createdOn":
                return formatDateTime(value);
            default:
                return value;
        }
    };
    return (_jsx("div", Object.assign({ style: { height: 300 }, className: "loan-lien-box" }, { children: _jsx(TableSortable, { data: liens, headCells: headCells, createRowData: createData, formatCell: formatCell }) })));
});
export default LienTable;
