var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ORIGINATORS_NAMES } from "utils/constants";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Originator } from "@analytics/common/types";
import { COMMON_NFTS, NFTFI_NFTS } from "@analytics/common/constants";
import { useEffect, useState } from "react";
import { getTokenIdsForTokenOffer, getTokenIdsForLiens } from "utils/queries";
import "./AnalyticsSelectors.sass";
export const OriginatorSelector = ({ originator, onChange, }) => {
    const ALL = { value: -1, name: "ALL" };
    const SELECTIONS = [ALL, ...ORIGINATORS_NAMES];
    return (_jsx("div", Object.assign({ className: "selector-box" }, { children: _jsx(Select, Object.assign({ labelId: "originator", id: "originator-select", value: originator.toString(), label: "Originator", onChange: ({ target }) => onChange(Number(target.value)) }, { children: SELECTIONS.map((originator, i) => (_jsx(MenuItem, Object.assign({ value: originator.value }, { children: originator.name }), `originator-${i}`))) })) })));
};
export const NftAddressSelector = ({ nftAddress, onChange, originator, }) => {
    let NFT_NAMES = [...COMMON_NFTS];
    if (originator === Originator.NFTFI) {
        NFT_NAMES = [...NFT_NAMES, ...NFTFI_NFTS];
    }
    return (_jsx("div", Object.assign({ className: "selector-box" }, { children: _jsx(Select, Object.assign({ labelId: "nftAddress", id: "nftAddress", value: nftAddress, label: "nftAddress", onChange: onChange }, { children: NFT_NAMES.map((nft, i) => (_jsx(MenuItem, Object.assign({ value: nft.nftAddress }, { children: nft.name }), `nftAddress-${i}`))) })) })));
};
export const TokenIdSelectorForLoanOffers = ({ nftAddress, originator, onChange, }) => {
    // fetch a list of all tokenIds based on the nftAddress
    const [selection, setSelection] = useState("All");
    const [tokenIds, setTokenIds] = useState(["All"]);
    const onSelectorChange = ({ target }) => {
        onChange(target.value);
        setSelection(target.value);
    };
    useEffect(() => {
        // fetch Token ids async
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            let tokenIdsRes = yield getTokenIdsForTokenOffer(nftAddress);
            tokenIdsRes = ["All", ...tokenIdsRes];
            setTokenIds(tokenIdsRes);
        });
        fetchData();
    }, [nftAddress, originator, onChange, selection]);
    if (originator === Originator.BLEND)
        return _jsx(_Fragment, {});
    return (_jsx("div", Object.assign({ className: "selector-box" }, { children: _jsx(Select, Object.assign({ labelId: "tokenId", id: "tokenId", value: selection, label: "tokenId", onChange: onSelectorChange }, { children: tokenIds.map((val, i) => (_jsx(MenuItem, Object.assign({ value: val }, { children: val }), `tokenId${i}`))) })) })));
};
export const TokenIdSelectorForLien = ({ nftAddress, originator, onChange, }) => {
    // fetch a list of all tokenIds based on the nftAddress
    const [selection, setSelection] = useState("All");
    const [tokenIds, setTokenIds] = useState(["All"]);
    const onSelectorChange = ({ target }) => {
        onChange(target.value);
        setSelection(target.value);
    };
    useEffect(() => {
        // fetch Token ids async
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            let tokenIdsRes = yield getTokenIdsForLiens(nftAddress);
            tokenIdsRes = ["All", ...tokenIdsRes];
            setTokenIds(tokenIdsRes);
        });
        fetchData();
    }, [nftAddress, originator, onChange]);
    if (originator === Originator.BLEND)
        return _jsx(_Fragment, {});
    return (_jsx("div", Object.assign({ className: "selector-box" }, { children: _jsx(Select, Object.assign({ labelId: "tokenId", id: "tokenId", value: selection, label: "tokenId", onChange: onSelectorChange }, { children: tokenIds.map((val, i) => (_jsx(MenuItem, Object.assign({ value: val }, { children: val }), `tokenId${i}`))) })) })));
};
export const CurrencySelector = ({ onChange }) => {
    // fetch a list of all tokenIds based on the nftAddress
    const conversions = [
        { name: "ETH", value: "eth" },
        { name: "USD", value: "usd" },
    ];
    const [selection, setSelection] = useState(conversions[0].value);
    const onSelectorChange = ({ target }) => {
        onChange(target.value);
        setSelection(target.value);
    };
    return (_jsx("div", Object.assign({ className: "selector-box" }, { children: _jsx(Select, Object.assign({ labelId: "currency", id: "currency", value: selection, label: "currency", onChange: onSelectorChange }, { children: conversions.map(({ value, name }, i) => (_jsx(MenuItem, Object.assign({ value: value }, { children: name }), `currency-${i}`))) })) })));
};
