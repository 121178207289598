var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Originator } from "@analytics/common/types";
import { useContext, useEffect, useState } from "react";
import { getLoanOffers } from "utils/queries";
import { removeOutliersIQRLoanOffers } from "utils/stats";
import { convertLoanOfferCurrency } from "./useConvertCurrency";
import { CurrencyContext } from "context/CurrencyContext";
// Remove loanOffers where loanOffer.offeredOn is undefined or null
export const removeEmpty = (loanOffers, thresholds) => {
    const minApy = (thresholds === null || thresholds === void 0 ? void 0 : thresholds.minApy) || 0;
    const maxApy = (thresholds === null || thresholds === void 0 ? void 0 : thresholds.maxApy) || 1;
    const minPrincipal = (thresholds === null || thresholds === void 0 ? void 0 : thresholds.minPrincipal) || 0;
    return loanOffers.filter(({ terms }) => terms.apy > minApy && terms.apy < maxApy && terms.principal > minPrincipal);
};
const filterAndConvertLoanOffers = (loanOffers, currencyData, opts) => {
    if (opts) {
        if (opts.filter) {
            if (opts.filter.empty) {
            }
            loanOffers = removeEmpty(loanOffers, {
                minApy: 0.05,
                maxApy: 0.8,
                minPrincipal: 0,
            });
            if (opts.filter.outliers) {
                loanOffers = removeOutliersIQRLoanOffers(loanOffers);
            }
        }
        if (opts.convertCurrencyTo && currencyData) {
            loanOffers = convertLoanOfferCurrency(loanOffers, opts === null || opts === void 0 ? void 0 : opts.convertCurrencyTo, currencyData);
        }
    }
    return loanOffers;
};
export const useLoanoffers = (nft, originator, tokenId, opts) => {
    const { currencyData } = useContext(CurrencyContext);
    const [loading, setLoading] = useState(true);
    const [loanOffers, setLoanOffers] = useState([]);
    // Fetch data
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            try {
                let data = yield getLoanOffers(nft.nftAddress, originator, originator === Originator.BLEND ? undefined : tokenId);
                // Filter data
                setLoanOffers(data);
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
            }
        });
        fetchData();
    }, [nft, originator, tokenId]);
    return [loading, filterAndConvertLoanOffers(loanOffers, currencyData, opts)];
};
