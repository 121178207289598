import _dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import _duration from "dayjs/plugin/duration";
/**
 * makeDuration creates a duration in seconds from a period
 * @param amount number of periods
 * @param period period of time
 * @returns number of seconds in duration
 */
export const makeDuration = (amount, period) => {
    const endDate = new Date();
    switch (period) {
        case "second":
        case "seconds":
            return amount;
        case "minute":
        case "minutes":
            return amount * 60;
        case "hour":
        case "hours":
            return amount * 3600;
        case "day":
        case "days":
            return amount * 86400;
        case "week":
        case "weeks":
            return amount * 604800;
        case "month":
        case "months":
            endDate.setMonth(endDate.getMonth() + amount);
            break;
        case "year":
        case "years":
            endDate.setFullYear(endDate.getFullYear() + amount);
            break;
    }
    return Math.floor((endDate.valueOf() - Date.now()) / 1000);
};
/**
 * Delay a request
 * @param ms milliseconds to delay a request
 * @returns
 */
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
/**
 * Generates a random number
 * @param min min number
 * @param max max number
 * @returns a random number between min and max
 */
export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const dateToEpoch = (dateString) => {
    if (dateString === "0")
        return 0;
    return Number((Date.parse(dateString) / 1000).toFixed(0));
};
export const isDateOrEpoch = (str) => {
    // Check if the string is a valid date
    const date = new Date(str);
    if (!isNaN(date.getTime())) {
        return "date";
    }
    // Check if the string is a valid epoch time
    const epoch = parseInt(str);
    if (!isNaN(epoch) && epoch >= 0) {
        const epochDate = new Date(epoch);
        if (!isNaN(epochDate.getTime())) {
            return "epoch";
        }
    }
    return "none";
};
_dayjs.extend(relativeTime);
_dayjs.extend(updateLocale);
_dayjs.extend(_duration);
_dayjs.updateLocale("en", {
    relativeTime: {
        future: "%s",
        past: "%s",
        s: "a few seconds",
        m: "1 minute",
        mm: "%d minutes",
        h: "1 hour",
        hh: "%d hours",
        d: "1 day",
        dd: "%d days",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years",
    },
});
export function secondsToDhms(seconds, options) {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    if (options === null || options === void 0 ? void 0 : options.onlyLargest) {
        if (d > 0) {
            return d + (d === 1 ? " day" : " days");
        }
        else if (h > 0) {
            return h + (h === 1 ? " hour" : " hours");
        }
        else if (m > 0) {
            return m + (m === 1 ? " minute" : " minutes");
        }
        else if (s > 0) {
            return s + (s === 1 ? " second" : " seconds");
        }
    }
    const dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
    const hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}
export const dayjs = _dayjs;
export const now = Math.floor(Date.now() / 1000);
/**
 * newDayJS creates a dayjs object from a unix timestamp denominated in seconds
 * @param timeStamp in seconds
 * @returns a dayjs object
 */
export const newDayJS = (timeStamp) => {
    return dayjs(timeStamp * 1000);
};
