import { jsx as _jsx } from "react/jsx-runtime";
import { Line } from "react-chartjs-2";
import { ChartJS } from "../Charts/Setup";
import { dayjs } from "utils/time";
// Register chartjs
ChartJS();
export const LienPrincipalOverTime = ({ aggregateLiens, }) => {
    if (!aggregateLiens || aggregateLiens.length < 1)
        return _jsx("div", { children: "Loading lien chart data..." });
    let labels = [];
    let apyData = [];
    let principalData = [];
    for (const al of aggregateLiens) {
        const { terms } = al.lien;
        labels.push(dayjs.unix(al.lien.startedOn).format("MM/DD/YY"));
        principalData.push(terms.principal);
        apyData.push(terms.apy * 100);
    }
    return (_jsx(Line, { height: 100, className: "line-chart", data: {
            labels,
            datasets: [
                {
                    label: "Principal",
                    data: principalData,
                    fill: false,
                    borderColor: "#229dc8",
                    yAxisID: "y-axis-1",
                    tension: 0.6,
                },
                {
                    label: "APY",
                    data: apyData,
                    fill: false,
                    borderColor: "#eda850",
                    yAxisID: "y-axis-2",
                    tension: 0.6,
                },
            ],
        }, options: {
            animation: false,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: "Date",
                    },
                },
                "y-axis-1": {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: "Principal",
                    },
                },
                "y-axis-2": {
                    beginAtZero: true,
                    position: "right",
                    title: {
                        display: true,
                        text: "APY",
                    },
                },
            },
        } }));
};
