import { jsx as _jsx } from "react/jsx-runtime";
import { Line } from "react-chartjs-2";
import { dayjs } from "utils/time";
import { ChartJS } from "../Charts/Setup";
// Register chartjs
ChartJS();
const NFTPriceChart = ({ nftHistorialData }) => {
    var _a;
    return (_jsx(Line, { className: "line-chart", height: "100px", data: {
            labels: (_a = nftHistorialData === null || nftHistorialData === void 0 ? void 0 : nftHistorialData.timestamps) === null || _a === void 0 ? void 0 : _a.map((el) => dayjs.unix(el / 1000).format("M/DD/YY")),
            datasets: [
                {
                    label: "Floor Price",
                    data: nftHistorialData === null || nftHistorialData === void 0 ? void 0 : nftHistorialData.floorEth,
                    fill: false,
                    borderColor: "#a7c057",
                    yAxisID: "y-axis-1",
                    tension: 0.5,
                    pointRadius: 1.5,
                },
                {
                    label: "Sales Count",
                    data: nftHistorialData === null || nftHistorialData === void 0 ? void 0 : nftHistorialData.salesCount,
                    fill: false,
                    borderColor: "#eda850",
                    yAxisID: "y-axis-2",
                    tension: 0.6,
                    pointRadius: 1.5,
                },
            ],
        }, options: {
            animation: false,
            scales: {
                x: { title: { display: true, text: "Date" } },
                "y-axis-1": {
                    beginAtZero: true,
                    position: "right",
                    title: { display: true, text: "Eth Price" },
                },
                "y-axis-2": {
                    beginAtZero: true,
                    title: { display: true, text: "Sales Count" },
                },
            },
        } }));
};
export default NFTPriceChart;
