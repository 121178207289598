import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dayjs } from "@analytics/common/utils";
import { useGasInEth } from "hooks";
import { getCombinedStats } from "../../utils/calculations";
import Box from "@mui/material/Box";
import StatsDetailBox from "components/StatsDetailBox";
import "./LienStats.sass";
const LienStats = ({ als, currency, dateRange }) => {
    const gasFeeEth = useGasInEth();
    if (dateRange) {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            als = als.filter(({ lien }) => {
                const startedOn = dayjs.unix(lien.startedOn);
                return startedOn >= dateRange[0] && startedOn <= dateRange[1];
            });
        }
    }
    let data = als.map(({ lien }) => {
        const { terms } = lien;
        return {
            principal: terms.principal,
            duration: terms.duration || -1,
            apy: terms.apy,
            createdAt: lien.startedOn,
        };
    });
    const lienStats = getCombinedStats(data, currency, gasFeeEth);
    return (_jsxs(Box, Object.assign({ className: "liens-stats" }, { children: [_jsx("div", Object.assign({ className: "splitter-box" }, { children: lienStats.slice(0, 3).map((stat) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, stat.name))) })), _jsx("div", Object.assign({ className: "splitter-box" }, { children: lienStats.slice(3, 6).map((stat) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, stat.name))) })), _jsx("div", Object.assign({ className: "splitter-box" }, { children: lienStats.slice(6, 9).map((stat) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, stat.name))) }))] })));
};
export default LienStats;
