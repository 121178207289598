import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApySegmentTable } from "../Charts";
function findLowestAPYForPrincipalSegment(loanOffers, principalIncrement, maxPrincipal) {
    const results = [];
    for (let principal = 0; principal <= maxPrincipal; principal += principalIncrement) {
        let minApy = Infinity;
        let count = 0;
        for (const offer of loanOffers) {
            if (offer.terms.principal >= principal &&
                offer.terms.principal < principal + principalIncrement) {
                minApy = Math.min(minApy, offer.terms.apy);
                count += 1;
            }
        }
        if (minApy !== Infinity) {
            results.push({ principal, apy: minApy, count });
        }
    }
    return results;
}
const LoanOffersSegments = ({ loanOffers }) => {
    const principalIncrement = 0.1;
    const maxPrincipal = Math.max(...loanOffers.map((offer) => offer.terms.principal));
    const chartData = findLowestAPYForPrincipalSegment(loanOffers, principalIncrement, maxPrincipal);
    return (_jsxs("div", Object.assign({ className: "loan-offers-stats" }, { children: [_jsx("p", { children: "This chart shows the highest principal amount for each step increase in APY from loan offers." }), _jsx(ApySegmentTable, { data: chartData, nftAddress: loanOffers[0].contractAddress })] })));
};
export default LoanOffersSegments;
