import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import NFTStats from "./NFTStats";
import Loader from "components/Loader";
import { dayjs } from "utils/time";
import useNFTStats from "hooks/useNFTStats";
import useNFTHistorialStats from "hooks/useNFTHistorialStats";
import { useState } from "react";
import DateRangeSelector from "components/Selectors/DateRange";
import NFTPriceChart from "./NFTPriceChart";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const NFTStatsCombined = ({ nft }) => {
    var _a;
    const [loadingData, nftData] = useNFTStats(nft);
    const [dateRange, setDateRange] = useState();
    const [loadingNHData, nftHistorialData] = useNFTHistorialStats(nft, dateRange);
    const [showStats, setShowStats] = useState(false);
    let initialDateRange = [null, null];
    if ((nftHistorialData === null || nftHistorialData === void 0 ? void 0 : nftHistorialData.timestamps) &&
        ((_a = nftHistorialData === null || nftHistorialData === void 0 ? void 0 : nftHistorialData.timestamps) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        const last = nftHistorialData.timestamps.at(-1);
        if (last && typeof last === "number") {
            initialDateRange = [
                dayjs.unix(nftHistorialData === null || nftHistorialData === void 0 ? void 0 : nftHistorialData.timestamps[0]),
                dayjs.unix(last),
            ];
        }
    }
    return (_jsxs("div", Object.assign({ className: "stats-wrapper" }, { children: [_jsxs("div", Object.assign({ className: "filter-box" }, { children: [_jsx(DateRangeSelector, { initialDateRange: initialDateRange, onChange: (val) => setDateRange(val) }), _jsx(FormControlLabel, { checked: showStats, onChange: (e, checked) => setShowStats(checked), control: _jsx(Switch, {}), label: "Show Stats" })] })), _jsx(Typography, Object.assign({ variant: "h6" }, { children: "Collection Stats" })), loadingNHData ? (_jsx(Loader, {})) : (_jsx(NFTPriceChart, { nftHistorialData: nftHistorialData })), loadingData ? (_jsx(Loader, {})) : showStats ? (_jsx(NFTStats, { nftData: nftData })) : (_jsx(_Fragment, {}))] })));
};
export default NFTStatsCombined;
