import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Originator } from "@analytics/common/types";
import { calcStats } from "../../utils/calculations";
import { dayjs } from "@analytics/common/utils";
import { StatsDetailCompBox } from "components/StatsDetailBox";
import "./Stats.sass";
import Typography from "@mui/material/Typography";
export const StatsAggregate = ({ loanOffers, aggregateLiens, currency, dateRange, }) => {
    if (dateRange) {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            loanOffers = loanOffers.filter((loan) => {
                return (dayjs(loan.offeredOn) >= dateRange[0] &&
                    dayjs(loan.offeredOn) <= dateRange[1]);
            });
        }
    }
    // Recommender
    // Loan Offers
    let dataBlend = loanOffers
        .filter((loanOffer) => loanOffer.originator === Originator.BLEND)
        .map((loanOffer) => {
        const { terms } = loanOffer;
        return {
            principal: terms.principal,
            duration: terms.duration,
            apy: terms.apy,
            createdAt: loanOffer.offeredOn,
        };
    });
    let dataNftFi = loanOffers
        .filter((loanOffer) => loanOffer.originator === Originator.NFTFI)
        .map((loanOffer) => {
        const { terms } = loanOffer;
        return {
            principal: terms.principal,
            duration: terms.duration,
            apy: terms.apy,
            createdAt: loanOffer.offeredOn,
        };
    });
    const loanOfferStatsNftFi = calcStats(dataNftFi, currency);
    const loanOfferStatsBlend = calcStats(dataBlend, currency);
    if ((loanOfferStatsBlend === null || loanOfferStatsBlend === void 0 ? void 0 : loanOfferStatsBlend.formatted.length) < 1 ||
        (loanOfferStatsNftFi === null || loanOfferStatsNftFi === void 0 ? void 0 : loanOfferStatsNftFi.formatted.length) < 1) {
        return _jsx(_Fragment, {});
    }
    const allLoanOfferStats = [];
    for (let i = 0; i < loanOfferStatsNftFi.formatted.length; i++) {
        const nftFi = loanOfferStatsNftFi.formatted[i];
        const blend = loanOfferStatsBlend.formatted[i];
        allLoanOfferStats.push({
            vals: [nftFi.val, blend.val],
            name: nftFi.name,
            unit: nftFi.unit,
        });
    }
    // Loan Offers
    let dataBlendLien = aggregateLiens
        .filter(({ lien }) => lien.originator === Originator.BLEND)
        .map(({ lien }) => {
        const { terms } = lien;
        return {
            principal: terms.principal,
            duration: terms.duration,
            apy: terms.apy,
            createdAt: lien.startedOn,
        };
    });
    let dataNftfiLien = aggregateLiens
        .filter(({ lien }) => lien.originator === Originator.NFTFI)
        .map(({ lien }) => {
        const { terms } = lien;
        return {
            principal: terms.principal,
            duration: terms.duration,
            apy: terms.apy,
            createdAt: lien.startedOn,
        };
    });
    const lienStatsNftFi = calcStats(dataNftfiLien, currency);
    const lienStatsBlend = calcStats(dataBlendLien, currency);
    const allLienStats = [];
    if (lienStatsBlend.formatted.length > 0) {
        for (let i = 0; i < loanOfferStatsNftFi.formatted.length; i++) {
            const nftFi = lienStatsNftFi.formatted[i];
            const blend = lienStatsBlend.formatted[i];
            if (nftFi && blend) {
                allLienStats.push({
                    vals: [nftFi.val, blend.val],
                    name: nftFi.name,
                    unit: nftFi.unit,
                });
            }
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "h6", sx: { color: "#b36514" } }, { children: "Cross Platforms" })), _jsxs("div", Object.assign({ className: "stats-aggregate-box", style: { display: "flex" } }, { children: [_jsxs("div", Object.assign({ className: "loan-offers-stats loan-offers-stats-aggregate" }, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: "Loan Offers" })), _jsx(StatsDetailCompBox, { label: "", values: ["NFTFi", "Blend"], suffix: "", className: "title" }, -1), allLoanOfferStats.slice(0, 4).map((stat, i) => (_jsx(StatsDetailCompBox, { label: stat.name, values: stat.vals, suffix: stat.unit }, i)))] })), _jsxs("div", Object.assign({ className: "loan-offers-stats loan-offers-stats-aggregate" }, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: "Past Loans" })), _jsx(StatsDetailCompBox, { label: "", values: ["NFTFi", "Blend"], suffix: "", className: "title" }, -1), allLienStats.slice(0, 4).map((stat, i) => (_jsx(StatsDetailCompBox, { label: stat.name, values: stat.vals, suffix: stat.unit }, i)))] }))] }))] }));
};
export default StatsAggregate;
