import { createTheme } from "@mui/material/styles";
const lightGreen = "#CFE934";
const caravaggioBlack = "#271B10";
const midGrey = "#7e8084";
const lightLight = "#c4c4c4";
// const light = "#F9F8F6";
const redAlert = "#EB6237";
const lightBlue = "#B6CDDD";
const theme = createTheme({
    palette: {
        primary: {
            main: lightGreen,
            dark: "rgb(182 207 38)",
            contrastText: caravaggioBlack,
        },
        secondary: {
            main: lightBlue,
        },
        info: {
            main: caravaggioBlack,
        },
        error: {
            main: redAlert,
            contrastText: "white",
        },
    },
    typography: {
        fontFamily: [
            "Nunito",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        body1: { fontSize: 15, color: caravaggioBlack },
        body2: { fontSize: 15, color: midGrey },
        caption: { fontSize: 15, color: caravaggioBlack, fontWeight: 600 },
        h1: { fontSize: 66, color: caravaggioBlack },
        h3: { color: caravaggioBlack },
        h5: { fontSize: 25, fontWeight: 600, color: caravaggioBlack },
        h6: { fontSize: 19, color: caravaggioBlack },
        subtitle1: {
            fontSize: 12,
            color: midGrey,
            lineHeight: "1.4em",
            textTransform: "uppercase",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    padding: "12px 25px",
                    boxShadow: `0px 0px 4px -2px ${midGrey}`,
                    "&.Mui-disabled": {
                        color: midGrey,
                        boxShadow: `none`,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                    padding: "5px 0",
                },
                head: {
                    borderBottom: "none",
                    padding: "5px 0",
                    fontWeight: 500,
                    "border-bottom": "1px solid black",
                    background: lightLight,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: "white",
                },
            },
        },
    },
});
export default theme;
