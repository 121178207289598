import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { Button } from "@mui/material";
import { dayjs } from "@analytics/common/utils";
import "./DateRange.sass";
const DateRangeSelector = ({ initialDateRange, onChange, }) => {
    const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
    const [customDateRange, setCustomDateRange] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    // Function to set date range based on days
    const setDateRangeInDays = (days) => {
        const startDate = dayjs().subtract(days, "day");
        const endDate = dayjs();
        return [startDate, endDate];
    };
    const handleDateChange = (date) => {
        setSelectedDateRange(date);
        onChange(date);
    };
    const ButtonDateRange = ({ name, days }) => {
        return (_jsx(Button, Object.assign({ className: "short-cut-button", style: {
                backgroundColor: selectedRange === name ? "#888" : undefined,
            }, onClick: () => {
                setSelectedRange(name);
                handleDateChange(setDateRangeInDays(days));
            } }, { children: name })));
    };
    return (_jsx("div", Object.assign({ className: "date-picker" }, { children: !customDateRange ? (_jsxs(_Fragment, { children: [_jsx(ButtonDateRange, { name: "1 D", days: 1 }), _jsx(ButtonDateRange, { name: "1 W", days: 7 }), _jsx(ButtonDateRange, { name: "1 M", days: 30 }), _jsx(ButtonDateRange, { name: "1 Y", days: 365 }), _jsx(Button, Object.assign({ className: "short-cut-button", onClick: () => setCustomDateRange(true), style: { backgroundColor: customDateRange ? "#888" : undefined } }, { children: "Custom" }))] })) : (_jsxs(_Fragment, { children: [_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DateRangePicker, { slots: { field: SingleInputDateRangeField }, value: selectedDateRange, onChange: handleDateChange, maxDate: initialDateRange[1] ? initialDateRange[1] : undefined, disableFuture: true, className: "date-range-picker" }) })), _jsx(Button, Object.assign({ variant: "outlined", sx: { marginLeft: "10px" }, color: "warning", onClick: () => {
                        handleDateChange([null, null]);
                        setCustomDateRange(false);
                        setSelectedRange(null);
                    } }, { children: "X" }))] })) })));
};
export default DateRangeSelector;
