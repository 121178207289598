import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { ALL_NFTS } from "@analytics/common/constants";
import { NFTStatsContext } from "context/NFTStats";
import { CurrencyContext } from "context/CurrencyContext";
import { findCurrency } from "hooks";
import StatsDetailBox from "components/StatsDetailBox";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import "./SideBar.sass";
import { useFavorites } from "hooks/useFavorites";
import Loader from "components/Loader";
const Sidebar = ({ onChange }) => {
    // Load favorites from local storage and set initial state
    const { favorites, addFavorite, removeFavorite, isFavorite, sortedNFTs } = useFavorites({ allNfts: ALL_NFTS });
    const [selected, setSelected] = useState(null);
    const nftStats = useContext(NFTStatsContext);
    const [show, setShow] = useState(true);
    const { currencyData: CURRENCIES } = useContext(CurrencyContext);
    useEffect(() => {
        if (sortedNFTs.length > 0 && !selected) {
            setSelected(sortedNFTs[0]);
        }
    }, [sortedNFTs, selected]);
    // Save favorites to local storage whenever the 'favorites' state changes
    useEffect(() => {
        localStorage.setItem("favorites", JSON.stringify(favorites));
    }, [favorites]);
    // Trigger 'onChange' whenever 'selected' changes
    useEffect(() => {
        if (selected)
            onChange(selected);
    }, [selected, onChange]);
    const onSelect = ({ currentTarget }) => {
        var _a;
        try {
            if ((_a = currentTarget === null || currentTarget === void 0 ? void 0 : currentTarget.dataset) === null || _a === void 0 ? void 0 : _a.value) {
                const nft = JSON.parse(currentTarget.dataset.value);
                setSelected(nft);
                onChange(nft);
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    const toggleFavorite = (nftAddress) => {
        if (isFavorite(nftAddress))
            removeFavorite(nftAddress);
        else
            addFavorite(nftAddress);
    };
    const hasNFTStats = sortedNFTs && (sortedNFTs === null || sortedNFTs === void 0 ? void 0 : sortedNFTs.length) > 0 && nftStats && (nftStats === null || nftStats === void 0 ? void 0 : nftStats.length) > 0;
    return (_jsxs("div", Object.assign({ className: `sidebar-wrapper ${show ? "" : "hide-sidebar"}` }, { children: [_jsx("div", Object.assign({ className: "btn-hide", onClick: () => setShow((a) => !a) }, { children: _jsx(Typography, { children: show ? "Hide" : "Show" }) })), _jsx("div", Object.assign({ className: "main-sidebar" }, { children: _jsx("ul", { children: hasNFTStats ? (sortedNFTs.map((item, i) => {
                        var _a;
                        let analytics = _jsx(_Fragment, {});
                        // Empty stats
                        const data = nftStats.find((el) => el.nftAddress === item.nftAddress);
                        if (!data || (data && !(data === null || data === void 0 ? void 0 : data.stats)))
                            return analytics;
                        // Get stats
                        const { loanOffer, nft } = data.stats;
                        const terms = (_a = loanOffer === null || loanOffer === void 0 ? void 0 : loanOffer.mostRecent) === null || _a === void 0 ? void 0 : _a.terms;
                        const convertedPrincipal = (terms === null || terms === void 0 ? void 0 : terms.principal)
                            ? terms.principal *
                                findCurrency(CURRENCIES, terms.currency, "eth")
                            : -1;
                        analytics = (_jsxs("div", Object.assign({ className: "stats-box" }, { children: [_jsxs("div", Object.assign({ className: "splitter-box" }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: "Collection stats" })), _jsx(StatsDetailBox, { label: "Floor", value: nft.floor_price, suffix: "eth", focus: true }, i + "a"), _jsx(StatsDetailBox, { label: "1D change", value: nft.one_day_change, suffix: "%", stylePos: true }, i + "b")] })), _jsxs("div", Object.assign({ className: "splitter-box" }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: "Last Offer" })), _jsx(StatsDetailBox, { label: "LTV", value: nft.floor_price
                                                ? (convertedPrincipal / nft.floor_price) * 100
                                                : undefined, suffix: "%", stylePos: true, focus: true }, i + "x"), _jsx(StatsDetailBox, { label: "Apy", value: (terms === null || terms === void 0 ? void 0 : terms.apy) !== undefined ? terms.apy * 100 : undefined, suffix: "%" }, i + "y")] }))] }), i));
                        return (_jsx(MenuItem, Object.assign({ "data-value": JSON.stringify(item), selected: selected ? item.name === selected.name : false, onClick: onSelect, className: "menu-item" }, { children: _jsxs("div", Object.assign({ className: "content-box" }, { children: [_jsxs("div", Object.assign({ className: "header" }, { children: [_jsx("img", { src: item.img, alt: item.name }), _jsx(Typography, Object.assign({ className: "header-name" }, { children: item.name })), _jsx(IconButton, Object.assign({ className: "favorite-btn", onClick: () => toggleFavorite(item.nftAddress), color: favorites.includes(item.nftAddress)
                                                    ? "secondary"
                                                    : "default" }, { children: favorites.includes(item.nftAddress) ? (_jsx(Favorite, {})) : (_jsx(FavoriteBorder, {})) }))] })), analytics] })) }), item.nftAddress));
                    })) : (_jsx(Loader, {})) }) }))] })));
};
export default Sidebar;
