export const displayStat = (val) => {
    if (val === undefined || val === null)
        return undefined;
    return val;
};
export const toFixed = (number, decimals) => {
    const fixedNumber = number.toFixed(decimals); // Fix the number to two decimal places
    if (fixedNumber.endsWith("00"))
        return fixedNumber.slice(0, -3);
    return fixedNumber;
};
