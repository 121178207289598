export const formatNumber = (numberString, decimals) => {
    if (numberString === "-1")
        return -1;
    try {
        return new Intl.NumberFormat("en-US", {
            notation: "compact",
            compactDisplay: "short",
            maximumFractionDigits: decimals || 2,
        }).format(parseFloat(numberString));
    }
    catch (e) {
        return numberString;
    }
};
