import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StatsDetailBox from "components/StatsDetailBox";
import "./NFTStats.sass";
const NFTStats = ({ nftData }) => {
    var _a;
    if (!nftData)
        return _jsx("div", { children: " Loading nft data ... " });
    let stats = (_a = nftData === null || nftData === void 0 ? void 0 : nftData.collection) === null || _a === void 0 ? void 0 : _a.stats;
    if (!stats)
        return _jsx("div", { children: " No Stats " });
    return (_jsxs("div", Object.assign({ className: "nft-stats" }, { children: [_jsxs("div", Object.assign({ className: "splitter-box" }, { children: [_jsx(StatsDetailBox, { label: "Floor", value: stats.floor_price, suffix: "eth" }), _jsx(StatsDetailBox, { label: "7D avg price", value: stats.seven_day_average_price, suffix: "eth" }), _jsx(StatsDetailBox, { label: "30D avg price", value: stats.thirty_day_average_price, suffix: "eth" })] })), _jsxs("div", Object.assign({ className: "splitter-box mid-one" }, { children: [_jsx(StatsDetailBox, { label: "1D change", value: stats.one_day_change, suffix: "%", stylePos: true }), _jsx(StatsDetailBox, { label: "7D change", value: stats.seven_day_change, suffix: "%", stylePos: true }), _jsx(StatsDetailBox, { label: "30D change", value: stats.thirty_day_change, suffix: "%", stylePos: true })] })), _jsxs("div", Object.assign({ className: "splitter-box mid-two" }, { children: [_jsx(StatsDetailBox, { label: "1D vol", value: stats.one_day_volume, suffix: "eth" }), _jsx(StatsDetailBox, { label: "7D vol", value: stats.seven_day_volume, suffix: "eth" }), _jsx(StatsDetailBox, { label: "30D vol", value: stats.thirty_day_volume, suffix: "eth" })] })), _jsxs("div", Object.assign({ className: "splitter-box" }, { children: [_jsx(StatsDetailBox, { label: "Market cap", value: stats.market_cap, suffix: "eth" }), _jsx(StatsDetailBox, { label: "Owners", value: stats.num_owners }), _jsx(StatsDetailBox, { label: "Total supply", value: stats.total_supply })] }))] })));
};
export default NFTStats;
