import { CURRENCIES_ARR } from "@analytics/common/constants";
import { Originator } from "@analytics/common/types";
export const ORIGINATORS_NAMES = [
    { value: Originator.NFTFI, name: "NftFi" },
    { value: Originator.BLEND, name: "Blend" },
];
export const COINGECKO_SIMPLE_PRICE_URI = `https://api.coingecko.com/api/v3/simple/price`;
export const ETH_GAS_STATION_URI = "https://ethgasstation.info/api/ethgasAPI.json?";
export const NFT_PRICE_FLOOR_URI = "https://api-bff.nftpricefloor.com/projects";
export const CURRENCIES = CURRENCIES_ARR;
