import { LoanStatus, Originator } from "../types";
export function getLienStatusString(status) {
    const statusToStringMap = {
        [LoanStatus.FUNDING]: "Funding",
        [LoanStatus.MIN_FUNDED]: "Min Funded",
        [LoanStatus.MAX_FUNDED]: "Active",
        [LoanStatus.ACTIVE]: "Active",
        [LoanStatus.WITHDRAWN]: "Withdrawn",
        [LoanStatus.REPAID]: "Repaid",
        [LoanStatus.LIQUIDATED]: "Liquidated",
        [LoanStatus.CANCELLED]: "Cancelled",
        [LoanStatus.DEFAULTED]: "Defaulted",
        [LoanStatus.AUCTION]: "Auctioning",
    };
    return statusToStringMap[status];
}
export function getOriginatorString(originator) {
    const originatorToStringMap = {
        [Originator.ALL]: "All",
        [Originator.BLEND]: "Blend",
        [Originator.NFTFI]: "NFTFi",
        [Originator.BENDDAO]: "BendDAO",
        [Originator.ARCADE]: "Arcade",
        [Originator.NAU]: "Nau",
        [Originator.X2Y2]: "X2Y2",
    };
    return originatorToStringMap[originator];
}
