import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";
import "./NauAccordion.sass";
const NauAccordion = ({ title, id, children, loading, index, }) => {
    return (_jsxs(Accordion, Object.assign({ className: "accordion", TransitionProps: { unmountOnExit: true }, defaultExpanded: index === 0 }, { children: [_jsxs(AccordionSummary, Object.assign({ expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": `panel-${id}-content`, id: `panel-${id}-header` }, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: title })), loading ? _jsx(LinearProgress, { sx: { width: "100%" } }) : null] })), _jsx(AccordionDetails, { children: children })] })));
};
export default NauAccordion;
