import { getAddress } from "ethers";
export function shortenString(str) {
    return str.substring(0, 5) + "..." + str.substring(str.length - 3);
}
/**
 * @public
 */
export function shortenAddress(address) {
    try {
        const formattedAddress = getAddress(address);
        return shortenString(formattedAddress);
    }
    catch (_a) {
        throw new TypeError("Invalid input, address can't be parsed");
    }
}
/**
 * @public
 */
export function shortenIfAddress(address) {
    if (typeof address === "string" && address.length > 0) {
        return shortenAddress(address);
    }
    return "";
}
