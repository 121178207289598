import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import { secondsToDhms } from "utils/time";
import { Originator } from "@analytics/common/types";
import { ORIGINATOR_URLS } from "@analytics/common/constants";
import { formatNumber } from "utils/numbers";
import TableSortable, { formatDateTime, formatPercentage, } from "components/TableSortable";
const getLoanLink = (tokenId, contractAddress, originator) => {
    // Generate the loan link based on the tokenId
    // Replace the return statement with the appropriate logic
    let url = `${ORIGINATOR_URLS.nftfi}/${contractAddress}/${tokenId}`;
    let text = tokenId;
    if (originator === Originator.BLEND) {
        text = -1;
        url = `${ORIGINATOR_URLS.blend}/`;
    }
    return _jsx("a", Object.assign({ href: url }, { children: text }));
};
let nftfiHeadCells = [
    {
        id: "tokenId",
        numeric: false,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "apy",
        numeric: true,
        disablePadding: false,
        label: "APY",
    },
    {
        id: "principal",
        numeric: true,
        disablePadding: false,
        label: "Principal",
    },
    {
        id: "duration",
        numeric: true,
        disablePadding: false,
        label: "Duration",
    },
    {
        id: "offeredOn",
        numeric: true,
        disablePadding: false,
        label: "OfferedOn",
    },
    {
        id: "expiry",
        numeric: true,
        disablePadding: false,
        label: "ExpiresOn",
    },
];
let blendHeadCells = [
    {
        id: "apy",
        numeric: true,
        disablePadding: true,
        label: "APY",
    },
    {
        id: "principal",
        numeric: true,
        disablePadding: false,
        label: "Principal",
    },
    {
        id: "offeredOn",
        numeric: true,
        disablePadding: false,
        label: "OfferedOn",
    },
];
const createData = (offer) => {
    var _a;
    const { terms } = offer;
    return {
        tokenId: offer.tokenId || -1,
        apy: terms.apy,
        principal: terms.principal,
        duration: terms.duration || -1,
        lender: ((_a = offer.lender) === null || _a === void 0 ? void 0 : _a.address) || "n/a",
        offeredOn: offer.offeredOn || -1,
        expiry: offer.terms.expiry || -1,
    };
};
const LoanOfferTable = memo(({ loanOffers, originator }) => {
    let table;
    switch (originator) {
        case Originator.BLEND:
            const blendFormatCell = (key, value) => {
                switch (key) {
                    case "apy":
                        return formatPercentage(value);
                    case "principal":
                        return formatNumber(value);
                    case "offeredOn":
                        return formatDateTime(value);
                    default:
                        return value;
                }
            };
            table = (_jsx(TableSortable, { data: loanOffers, headCells: blendHeadCells, createRowData: createData, formatCell: blendFormatCell }));
            break;
        default:
            const nftFiFormatCell = (key, value) => {
                switch (key) {
                    case "tokenId":
                        return getLoanLink(value, loanOffers[0].contractAddress);
                    case "apy":
                        return formatPercentage(value);
                    case "principal":
                        return formatNumber(value);
                    case "duration":
                        return secondsToDhms(value, { onlyLargest: true });
                    case "offeredOn":
                        return formatDateTime(value);
                    case "expiry":
                        return formatDateTime(value);
                    default:
                        return value;
                }
            };
            table = (_jsx(TableSortable, { data: loanOffers, headCells: nftfiHeadCells, createRowData: createData, formatCell: nftFiFormatCell }));
            break;
    }
    return (_jsx("div", Object.assign({ style: { height: 300 }, className: "loan-lien-box" }, { children: table })));
});
export default LoanOfferTable;
