import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import CurrencyProvider from "context/CurrencyContext";
import NFTStatsProvider from "context/NFTStats";
import theme from "./utils/styles";
import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800.css";
const Root = () => {
    return (_jsx(React.StrictMode, { children: _jsx(BrowserRouter, Object.assign({ basename: "/" }, { children: _jsx(CurrencyProvider, { children: _jsx(NFTStatsProvider, { children: _jsx(StyledEngineProvider, Object.assign({ injectFirst: true }, { children: _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsx(App, {}) })) })) }) }) })) }));
};
ReactDOM.render(_jsx(Root, {}), document.getElementById("root"));
