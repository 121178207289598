var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, forwardRef, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { dayjs } from "utils/time";
import { TableVirtuoso } from "react-virtuoso";
export const formatNumber = (value) => {
    if (!value)
        return "n/a";
    if (value === 0)
        return "0";
    if (typeof value !== "number")
        value = Number(value);
    return Number(value.toFixed(3));
};
export const formatPercentage = (value) => {
    return `${(value * 100).toFixed(2)}%`;
};
export const formatDateTime = (time, opts) => {
    const dateTime = dayjs.unix(time);
    let preFix = "";
    let postFix = " ago";
    if (dateTime.isAfter(dayjs())) {
        preFix = "in ";
        postFix = "";
    }
    return `${dateTime.format("MM/DD")} ${(opts === null || opts === void 0 ? void 0 : opts.compact) ? "" : `(${preFix} ${dateTime.fromNow()} ${postFix})`}`;
};
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        if (onRequestSort)
            onRequestSort(event, property);
    };
    return (_jsx(TableRow, Object.assign({ sx: { background: "white" } }, { children: headCells.map((headCell, i) => (_jsx(TableCell, Object.assign({ padding: "none", align: i === 0 ? "left" : "right", sortDirection: orderBy === headCell.id ? order : false, width: headCell.width }, { children: _jsxs(TableSortLabel, Object.assign({ active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : "asc", onClick: createSortHandler(headCell.id) }, { children: [headCell.label, orderBy === headCell.id ? (_jsx(Box, Object.assign({ component: "span", sx: visuallyHidden }, { children: order === "desc" ? "sorted descending" : "sorted ascending" }))) : null] })) }), headCell.id))) })));
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy])
        return -1;
    if (b[orderBy] > a[orderBy])
        return 1;
    return 0;
}
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
const TableSortable = ({ data, headCells, createRowData, formatCell, }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const rows = useMemo(() => data.map(createRowData) || [], [data, createRowData]);
    // TODO: Remove this. This is temp
    useEffect(() => {
        window.addEventListener("error", (e) => {
            if (e.message === "ResizeObserver loop limit exceeded" ||
                e.message ===
                    "ResizeObserver loop completed with undelivered notifications.") {
                const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
                const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    }, []);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const visibleRows = useMemo(() => rows.slice().sort(getComparator(order, orderBy)), [order, orderBy, rows]);
    if ((data === null || data === void 0 ? void 0 : data.length) < 1)
        return _jsx("div", { children: "Loading Data..." });
    const VirtuosoTableComponents = {
        Scroller: forwardRef((props, ref) => (_jsx(TableContainer, Object.assign({ component: Paper }, props, { ref: ref })))),
        Table: (props) => {
            return (_jsx(Table, Object.assign({}, props, { size: "small", sx: { borderCollapse: "separate", tableLayout: "fixed" } })));
        },
        TableHead,
        TableRow: (_a) => {
            var { item: _item } = _a, props = __rest(_a, ["item"]);
            return _jsx(TableRow, Object.assign({ hover: true, tabIndex: -1 }, props));
        },
        TableBody: forwardRef((props, ref) => (_jsx(TableBody, Object.assign({}, props, { ref: ref })))),
    };
    const rowContent = (_index, row) => {
        return headCells.map((headCell, i) => (_jsx(TableCell, Object.assign({ padding: "none", align: i === 0 ? "left" : "right", width: headCell.width }, { children: formatCell(headCell.id, row[headCell.id]) }), headCell.id)));
    };
    if (data.length < 1)
        return _jsx("div", { children: "Loading data..." });
    return (_jsx("div", Object.assign({ style: { height: "100%" } }, { children: _jsx(TableVirtuoso, { data: visibleRows, components: VirtuosoTableComponents, fixedHeaderContent: () => (_jsx(EnhancedTableHead, { data: data, headCells: headCells, createRowData: createRowData, formatCell: formatCell, order: order, orderBy: orderBy, onRequestSort: handleRequestSort })), itemContent: rowContent }) })));
};
export default TableSortable;
