import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import { displayStat, toFixed } from "@analytics/common/utils";
import { formatNumber } from "utils/numbers";
import "./StatsDetailBox.sass";
import ethSVG from "assets/ethereum.svg";
export const getLTVClass = (value, threshold) => {
    return value < (threshold || 80) ? "stats-positive " : "stats-negative ";
};
export const getStatsClassByValue = (value) => {
    return value > 0 ? "stats-positive " : "stats-negative ";
};
const StatsDetailBox = ({ label, value, suffix, stylePos, focus = false, }) => {
    const isNumber = typeof value === "number";
    if (value === undefined)
        return _jsx(_Fragment, {});
    let className = "";
    // Adds styling class for positive/negative values
    if (isNumber) {
        value = displayStat(Number(value));
        if (value === undefined)
            return _jsx(_Fragment, {});
        if (stylePos) {
            if (label.indexOf("change") >= 0) {
                className = getStatsClassByValue(value);
            }
            else if (label.indexOf("LTV") >= 0) {
                className = getLTVClass(value);
            }
        }
        value = formatNumber(toFixed(value, 2));
    }
    if (focus)
        className = `${className}focus `;
    return (_jsxs("div", Object.assign({ className: className + "stats-detail-box" }, { children: [_jsx(Typography, Object.assign({ className: "label" }, { children: label })), _jsxs("span", Object.assign({ className: "val-box" }, { children: [_jsx(Typography, Object.assign({ className: "val-content" }, { children: value })), suffix === "eth" ? (_jsx("img", { className: "eth-icon", src: ethSVG, alt: "eth" })) : (_jsx(Typography, Object.assign({ className: "suffix" }, { children: suffix })))] }))] })));
};
export const StatsDetailCompBox = ({ label, values, suffix, className, }) => {
    if (values === undefined)
        return _jsx(_Fragment, {});
    return (_jsxs("div", Object.assign({ className: `stats-detail-box stats-aggregate ${className}` }, { children: [_jsx(Typography, Object.assign({ className: "label" }, { children: label })), values.map((value) => (_jsxs("span", Object.assign({ className: "val-box" }, { children: [_jsx(Typography, Object.assign({ className: className + " val-content" }, { children: typeof value === "number"
                            ? formatNumber(toFixed(value, 2))
                            : value })), suffix === "eth" ? (_jsx("img", { className: "eth-icon", src: ethSVG, alt: "eth" })) : (_jsx(Typography, Object.assign({ className: "suffix" }, { children: suffix })))] }))))] })));
};
export default StatsDetailBox;
