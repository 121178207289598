import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Originator } from "@analytics/common/types";
import LienTablePortfolio from "components/Liens/LienTablePortfolio";
import { CurrencySelector, OriginatorSelector, } from "components/Selectors/Analytics";
import Typography from "@mui/material/Typography";
import DateRangeSelector from "components/Selectors/DateRange";
import { dayjs } from "utils/time";
import "./Dashboard.sass";
import Loader from "components/Loader";
import { useLenderHistory } from "hooks/useLiens";
import "./Portfolio.sass";
const PortfolioPresenter = ({ originator, setOriginator, setCurrency, setDateRange, isLoading, liens, }) => {
    let initialDateRange = [null, null];
    return (_jsxs("div", Object.assign({ className: "stats-wrapper" }, { children: [_jsxs("div", Object.assign({ className: "filter-box" }, { children: [_jsx(OriginatorSelector, { originator: originator, onChange: setOriginator }), _jsx(CurrencySelector, { onChange: setCurrency }), _jsx(DateRangeSelector, { initialDateRange: initialDateRange, onChange: setDateRange })] })), _jsx(Typography, Object.assign({ variant: "h6", sx: { color: "##2673b1" } }, { children: "Loan Portfolio" })), isLoading ? _jsx(Loader, {}) : null, liens.length > 1 ? (_jsx(LienTablePortfolio, { liens: liens })) : (_jsx(_Fragment, { children: " No Lien Data " }))] })));
};
const Portfolio = () => {
    const USER_WALLET = "0x8bea90f540942a4ed18f353f1f440b3bc7d120f5";
    const [dateRange, setDateRange] = useState();
    const [originator, setOriginator] = useState(Originator.ALL); //TODO: Should all -1 to originators
    const [currency, setCurrency] = useState("eth");
    let [loadingAL, aggregateLiens] = useLenderHistory(USER_WALLET, {
        convertCurrencyTo: currency,
    });
    // Filter data based on date range
    if (dateRange && dateRange[0] !== null && dateRange[1] !== null) {
        aggregateLiens = aggregateLiens.filter(({ lien }) => {
            const startedOn = dayjs.unix(lien.startedOn);
            return (startedOn.isAfter(dateRange[0]) && startedOn.isBefore(dateRange[1]));
        });
    }
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: "portfolio-box" }, { children: _jsx(PortfolioPresenter, { originator: originator, setOriginator: (val) => setOriginator(val), setCurrency: (val) => setCurrency(val), setDateRange: (val) => setDateRange(val), isLoading: loadingAL, liens: aggregateLiens }) })) }));
};
export default Portfolio;
