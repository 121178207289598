import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Originator } from "@analytics/common/types";
import { useGasInEth } from "hooks";
import Typography from "@mui/material/Typography";
import { getCombinedStats } from "../../utils/calculations";
import StatsDetailBox from "components/StatsDetailBox";
import "./Stats.sass";
export const StatsPresenter = ({ data }) => {
    return (_jsx("ul", Object.assign({ className: "stats-list" }, { children: data.map((stat, i) => (_jsxs("li", Object.assign({ className: "stat-box" }, { children: [_jsxs(Typography, Object.assign({ className: "stat-label" }, { children: [stat.name, ":"] })), _jsxs("span", Object.assign({ className: "stats-val-box" }, { children: [_jsxs(Typography, Object.assign({ className: "stat-val" }, { children: [stat.val, " "] })), _jsxs(Typography, Object.assign({ className: "stat-unit" }, { children: [stat.unit, " "] }))] }))] }), i))) })));
};
const LOStatsNFTFIPresenter = ({ data }) => {
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "splitter-box" }, { children: data.slice(0, 3).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 0), _jsx("div", Object.assign({ className: "splitter-box" }, { children: data.slice(3, 6).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 1), _jsx("div", Object.assign({ className: "splitter-box" }, { children: data.slice(6, 9).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 2), _jsx("div", Object.assign({ className: "splitter-box competitive" }, { children: data.slice(9, 12).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 3)] }));
};
/**
 * Blend
 */
const LOStatsBlendPresenter = ({ data }) => {
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "splitter-box" }, { children: data.slice(4, 6).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 6), _jsx("div", Object.assign({ className: "splitter-box" }, { children: data.slice(2, 4).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 5), _jsx("div", Object.assign({ className: "splitter-box" }, { children: data.slice(0, 2).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 4), _jsx("div", Object.assign({ className: "splitter-box" }, { children: data.slice(6, 8).map((stat, i) => (_jsx(StatsDetailBox, { label: stat.name, value: stat.val, suffix: stat.unit }, i))) }), 7)] }));
};
const LoanOfferStats = ({ loanOffers, aggregateLiens, currency, originator, }) => {
    const gasFeeEth = useGasInEth();
    // Loan Offers
    let data = loanOffers.map((loanOffer) => {
        const { terms } = loanOffer;
        return {
            principal: terms.principal,
            duration: terms.duration,
            apy: terms.apy,
            createdAt: loanOffer.offeredOn,
        };
    });
    const allStats = getCombinedStats(data, currency, gasFeeEth);
    let statsPresenter;
    switch (originator) {
        case Originator.BLEND:
            statsPresenter = (_jsx(LOStatsBlendPresenter, { data: [
                    allStats[3],
                    allStats[4],
                    allStats[9],
                    allStats[10],
                    allStats[6],
                    allStats[8],
                    allStats[7],
                ] }));
            break;
        default:
            statsPresenter = _jsx(LOStatsNFTFIPresenter, { data: allStats });
            break;
    }
    return _jsx("div", Object.assign({ className: "loan-offers-stats" }, { children: statsPresenter }));
};
export default LoanOfferStats;
