import { sortBy } from "lodash";
export const COMMON_NFTS = [
    {
        nftAddress: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
        name: "Bored Ape Yacht Club",
        img: "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?w=50&auto=format",
        slug: "bored-ape-yacht-club",
    },
    {
        nftAddress: "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
        name: "Mutant Ape Yacht Club",
        img: "https://i.seadn.io/gae/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI?w=50&auto=format",
        slug: "mutant-ape-yacht-club",
    },
    {
        nftAddress: "0x5af0d9827e0c53e4799bb226655a1de152a425a5",
        name: "MiLady",
        img: "https://i.seadn.io/gae/a_frplnavZA9g4vN3SexO5rrtaBX_cBTaJYcgrPtwQIqPhzgzUendQxiwUdr51CGPE2QyPEa1DHnkW1wLrHAv5DgfC3BP-CWpFq6BA?w=50&auto=format",
        slug: "milady",
    },
    {
        nftAddress: "0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258",
        name: "Otherdeeds",
        img: "https://i.seadn.io/gae/yIm-M5-BpSDdTEIJRt5D6xphizhIdozXjqSITgK4phWq7MmAU3qE7Nw7POGCiPGyhtJ3ZFP8iJ29TFl-RLcGBWX5qI4-ZcnCPcsY4zI?w=50&auto=format",
        slug: "otherdeed",
    },
    {
        nftAddress: "0xb7f7f6c52f2e2fdb1963eab30438024864c313f6",
        name: "Wrapped CryptoPunks",
        img: "https://i.seadn.io/gcs/files/e0972d771af4d633e70e00338aa38e34.png?auto=format&dpr=1&w=256",
        slug: "v1-cryptopunks-wrapped",
    },
    {
        nftAddress: "0x8821bee2ba0df28761afff119d66390d594cd280",
        name: "DeGods",
        img: "https://i.seadn.io/gcs/files/c6cb0b1d6f2ab61c0efacf00e62e2230.jpg?w=50&auto=format",
        slug: "degods",
    },
    {
        nftAddress: "0xd3d9ddd0cf0a5f0bfb8f7fceae075df687eaebab",
        name: "Redacted Remilio Babies",
        img: "https://i.seadn.io/gcs/files/9d6168e731afd02d5e878eb03876cfd4.gif?w=50&auto=format",
        slug: "remilio-babies",
    },
    {
        nftAddress: "0xed5af388653567af2f388e6224dc7c4b3241c544",
        name: "Azuki",
        img: "https://i.seadn.io/gae/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT?w=50&auto=format",
        slug: "azuki",
    },
    {
        nftAddress: "0xacf63e56fd08970b43401492a02f6f38b6635c91",
        name: "Kanpai Panads",
        img: "https://i.seadn.io/gcs/files/74096ecd2c3119eca2bf0f59a3921455.gif?w=50&auto=format",
        slug: "kanpai-pandas",
    },
    {
        nftAddress: "0xba30e5f9bb24caa003e9f2f0497ad287fdf95623",
        name: "Bored Ape Kennel Club",
        img: "https://i.seadn.io/gcs/files/c4dfc6be4d9c5d4f073de2efe181416a.jpg?w=50&auto=format",
        slug: "bored-ape-kennel-club",
    },
    {
        nftAddress: "0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949",
        name: "Beanz",
        img: "https://i.seadn.io/gae/_R4fuC4QGYd14-KwX2bD1wf-AWjDF2VMabfqWFJhIgiN2FnAUpnD5PLdJORrhQ8gly7KcjhQZZpuzYVPF7CDSzsqmDh97z84j2On?w=50&auto=format",
        slug: "beanz-official",
    },
    {
        nftAddress: "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b",
        name: "CloneX",
        img: "https://i.seadn.io/gae/XN0XuD8Uh3jyRWNtPTFeXJg_ht8m5ofDx6aHklOiy4amhFuWUa0JaR6It49AH8tlnYS386Q0TW_-Lmedn0UET_ko1a3CbJGeu5iHMg?w=50&auto=format",
        slug: "clonex",
    },
    {
        nftAddress: "0xbd3531da5cf5857e7cfaa92426877b022e612cf8",
        name: "Pudgy Penguins",
        img: "https://i.seadn.io/gae/yNi-XdGxsgQCPpqSio4o31ygAV6wURdIdInWRcFIl46UjUQ1eV7BEndGe8L661OoG-clRi7EgInLX4LPu9Jfw4fq0bnVYHqg7RFi?w=50&auto=format",
        slug: "pudgy-penguins",
    },
];
export const NFTFI_NFTS = [
    {
        nftAddress: "0x23581767a106ae21c074b2276d25e5c3e136a68b",
        name: "Moon Birds",
        img: "https://i.seadn.io/gcs/files/4bce6187fea476154b311dafaf327c89.png?w=50&auto=format",
        slug: "proof-moonbirds",
    },
    {
        nftAddress: "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e",
        name: "Doodles",
        img: "https://i.seadn.io/gae/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ?w=50&auto=format",
        slug: "doodles",
    },
];
export const ALL_NFTS = sortBy([...COMMON_NFTS, ...NFTFI_NFTS], "name");
export const NFT_PRICE_FLOOR_URI = "https://api-bff.nftpricefloor.com/projects";
export const CURRENCIES_ARR = [
    {
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        name: "wETH",
        symbol: "weth",
    },
    {
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        name: "Dai",
        symbol: "dai",
    },
    {
        name: "USDC",
        symbol: "usd-coin",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    },
    {
        name: "Ethereum",
        symbol: "ethereum",
        address: null,
    },
    {
        name: "ETH",
        symbol: "ethereum",
        address: null,
    },
];
export const CURRENCIES_OBJ = {
    wETH: {
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        symbol: "weth",
    },
    Dai: {
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        symbol: "dai",
    },
    USDC: {
        symbol: "usd-coin",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    },
    Ethereum: {
        symbol: "ethereum",
        address: null,
    },
    ETH: {
        symbol: "ethereum",
        address: null,
    },
};
export const WEI = 1000000000000000000;
export const BPS = 10000;
export const PERCENT = 100;
export const DAY_SECS = 60 * 60 * 24;
export const YEAR_SECS = 31536000;
export const ORIGINATOR_URLS = {
    nftfi: "https://app.nftfi.com/assets",
    blend: "https://blur.io/collection",
};
export const ETHERSCAN_URI = "https://etherscan.io";
